/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Describes a single point in a exhibition floor plan
 * @export
 * @interface Point
 */
export interface Point {
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    x?: number;
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    y?: number;
}

export function PointFromJSON(json: any): Point {
    return PointFromJSONTyped(json, false);
}

export function PointFromJSONTyped(json: any, ignoreDiscriminator: boolean): Point {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'x': !exists(json, 'x') ? undefined : json['x'],
        'y': !exists(json, 'y') ? undefined : json['y'],
    };
}

export function PointToJSON(value?: Point | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'x': value.x,
        'y': value.y,
    };
}


