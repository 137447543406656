/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RfidAntenna,
    RfidAntennaFromJSON,
    RfidAntennaToJSON,
} from '../models';

export interface CreateRfidAntennaRequest {
    rfidAntenna: RfidAntenna;
    exhibitionId: string;
}

export interface DeleteRfidAntennaRequest {
    exhibitionId: string;
    rfidAntennaId: string;
}

export interface FindRfidAntennaRequest {
    exhibitionId: string;
    rfidAntennaId: string;
}

export interface ListRfidAntennasRequest {
    exhibitionId: string;
    roomId?: string;
    deviceGroupId?: string;
}

export interface UpdateRfidAntennaRequest {
    rfidAntenna: RfidAntenna;
    exhibitionId: string;
    rfidAntennaId: string;
}

/**
 * no description
 */
export class RfidAntennasApi extends runtime.BaseAPI {

    /**
     * Creates a new RFID antenna
     * Create an RFID antenna
     */
    async createRfidAntennaRaw(requestParameters: CreateRfidAntennaRequest): Promise<runtime.ApiResponse<RfidAntenna>> {
        if (requestParameters.rfidAntenna === null || requestParameters.rfidAntenna === undefined) {
            throw new runtime.RequiredError('rfidAntenna','Required parameter requestParameters.rfidAntenna was null or undefined when calling createRfidAntenna.');
        }

        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling createRfidAntenna.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/rfidAntennas`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RfidAntennaToJSON(requestParameters.rfidAntenna),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RfidAntennaFromJSON(jsonValue));
    }

    /**
     * Creates a new RFID antenna
     * Create an RFID antenna
     */
    async createRfidAntenna(requestParameters: CreateRfidAntennaRequest): Promise<RfidAntenna> {
        const response = await this.createRfidAntennaRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes RFID antenna.
     * Deletes RFID antenna.
     */
    async deleteRfidAntennaRaw(requestParameters: DeleteRfidAntennaRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling deleteRfidAntenna.');
        }

        if (requestParameters.rfidAntennaId === null || requestParameters.rfidAntennaId === undefined) {
            throw new runtime.RequiredError('rfidAntennaId','Required parameter requestParameters.rfidAntennaId was null or undefined when calling deleteRfidAntenna.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/rfidAntennas/{rfidAntennaId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))).replace(`{${"rfidAntennaId"}}`, encodeURIComponent(String(requestParameters.rfidAntennaId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes RFID antenna.
     * Deletes RFID antenna.
     */
    async deleteRfidAntenna(requestParameters: DeleteRfidAntennaRequest): Promise<void> {
        await this.deleteRfidAntennaRaw(requestParameters);
    }

    /**
     * Finds an RFID antenna by id
     * Find an RFID antenna
     */
    async findRfidAntennaRaw(requestParameters: FindRfidAntennaRequest): Promise<runtime.ApiResponse<RfidAntenna>> {
        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling findRfidAntenna.');
        }

        if (requestParameters.rfidAntennaId === null || requestParameters.rfidAntennaId === undefined) {
            throw new runtime.RequiredError('rfidAntennaId','Required parameter requestParameters.rfidAntennaId was null or undefined when calling findRfidAntenna.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/rfidAntennas/{rfidAntennaId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))).replace(`{${"rfidAntennaId"}}`, encodeURIComponent(String(requestParameters.rfidAntennaId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RfidAntennaFromJSON(jsonValue));
    }

    /**
     * Finds an RFID antenna by id
     * Find an RFID antenna
     */
    async findRfidAntenna(requestParameters: FindRfidAntennaRequest): Promise<RfidAntenna> {
        const response = await this.findRfidAntennaRaw(requestParameters);
        return await response.value();
    }

    /**
     * List RFID antennas
     * List RFID antennas
     */
    async listRfidAntennasRaw(requestParameters: ListRfidAntennasRequest): Promise<runtime.ApiResponse<Array<RfidAntenna>>> {
        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling listRfidAntennas.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.roomId !== undefined) {
            queryParameters['roomId'] = requestParameters.roomId;
        }

        if (requestParameters.deviceGroupId !== undefined) {
            queryParameters['deviceGroupId'] = requestParameters.deviceGroupId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/rfidAntennas`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RfidAntennaFromJSON));
    }

    /**
     * List RFID antennas
     * List RFID antennas
     */
    async listRfidAntennas(requestParameters: ListRfidAntennasRequest): Promise<Array<RfidAntenna>> {
        const response = await this.listRfidAntennasRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates RFID antenna.
     * Updates RFID antenna.
     */
    async updateRfidAntennaRaw(requestParameters: UpdateRfidAntennaRequest): Promise<runtime.ApiResponse<RfidAntenna>> {
        if (requestParameters.rfidAntenna === null || requestParameters.rfidAntenna === undefined) {
            throw new runtime.RequiredError('rfidAntenna','Required parameter requestParameters.rfidAntenna was null or undefined when calling updateRfidAntenna.');
        }

        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling updateRfidAntenna.');
        }

        if (requestParameters.rfidAntennaId === null || requestParameters.rfidAntennaId === undefined) {
            throw new runtime.RequiredError('rfidAntennaId','Required parameter requestParameters.rfidAntennaId was null or undefined when calling updateRfidAntenna.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/rfidAntennas/{rfidAntennaId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))).replace(`{${"rfidAntennaId"}}`, encodeURIComponent(String(requestParameters.rfidAntennaId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RfidAntennaToJSON(requestParameters.rfidAntenna),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RfidAntennaFromJSON(jsonValue));
    }

    /**
     * Updates RFID antenna.
     * Updates RFID antenna.
     */
    async updateRfidAntenna(requestParameters: UpdateRfidAntennaRequest): Promise<RfidAntenna> {
        const response = await this.updateRfidAntennaRaw(requestParameters);
        return await response.value();
    }

}
