/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeviceModelCapabilities,
    DeviceModelCapabilitiesFromJSON,
    DeviceModelCapabilitiesFromJSONTyped,
    DeviceModelCapabilitiesToJSON,
    DeviceModelDimensions,
    DeviceModelDimensionsFromJSON,
    DeviceModelDimensionsFromJSONTyped,
    DeviceModelDimensionsToJSON,
    DeviceModelDisplayMetrics,
    DeviceModelDisplayMetricsFromJSON,
    DeviceModelDisplayMetricsFromJSONTyped,
    DeviceModelDisplayMetricsToJSON,
    ScreenOrientation,
    ScreenOrientationFromJSON,
    ScreenOrientationFromJSONTyped,
    ScreenOrientationToJSON,
} from './';

/**
 * Describes device model used in exhibition
 * @export
 * @interface DeviceModel
 */
export interface DeviceModel {
    /**
     * 
     * @type {string}
     * @memberof DeviceModel
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceModel
     */
    manufacturer: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceModel
     */
    model: string;
    /**
     * 
     * @type {DeviceModelDimensions}
     * @memberof DeviceModel
     */
    dimensions: DeviceModelDimensions;
    /**
     * 
     * @type {DeviceModelDisplayMetrics}
     * @memberof DeviceModel
     */
    displayMetrics: DeviceModelDisplayMetrics;
    /**
     * 
     * @type {DeviceModelCapabilities}
     * @memberof DeviceModel
     */
    capabilities: DeviceModelCapabilities;
    /**
     * 
     * @type {ScreenOrientation}
     * @memberof DeviceModel
     */
    screenOrientation: ScreenOrientation;
    /**
     * 
     * @type {string}
     * @memberof DeviceModel
     */
    readonly creatorId?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceModel
     */
    readonly lastModifierId?: string;
    /**
     * Created date
     * @type {Date}
     * @memberof DeviceModel
     */
    readonly createdAt?: Date;
    /**
     * Date modified
     * @type {Date}
     * @memberof DeviceModel
     */
    readonly modifiedAt?: Date;
}

export function DeviceModelFromJSON(json: any): DeviceModel {
    return DeviceModelFromJSONTyped(json, false);
}

export function DeviceModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'manufacturer': json['manufacturer'],
        'model': json['model'],
        'dimensions': DeviceModelDimensionsFromJSON(json['dimensions']),
        'displayMetrics': DeviceModelDisplayMetricsFromJSON(json['displayMetrics']),
        'capabilities': DeviceModelCapabilitiesFromJSON(json['capabilities']),
        'screenOrientation': ScreenOrientationFromJSON(json['screenOrientation']),
        'creatorId': !exists(json, 'creatorId') ? undefined : json['creatorId'],
        'lastModifierId': !exists(json, 'lastModifierId') ? undefined : json['lastModifierId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (new Date(json['modifiedAt'])),
    };
}

export function DeviceModelToJSON(value?: DeviceModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'manufacturer': value.manufacturer,
        'model': value.model,
        'dimensions': DeviceModelDimensionsToJSON(value.dimensions),
        'displayMetrics': DeviceModelDisplayMetricsToJSON(value.displayMetrics),
        'capabilities': DeviceModelCapabilitiesToJSON(value.capabilities),
        'screenOrientation': ScreenOrientationToJSON(value.screenOrientation),
    };
}


