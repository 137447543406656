/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PageLayout,
    PageLayoutFromJSON,
    PageLayoutToJSON,
} from '../models';

export interface CreatePageLayoutRequest {
    pageLayout: PageLayout;
}

export interface DeletePageLayoutRequest {
    pageLayoutId: string;
}

export interface FindPageLayoutRequest {
    pageLayoutId: string;
}

export interface ListPageLayoutsRequest {
    deviceModelId?: string;
    screenOrientation?: string;
}

export interface UpdatePageLayoutRequest {
    pageLayout: PageLayout;
    pageLayoutId: string;
}

/**
 * no description
 */
export class PageLayoutsApi extends runtime.BaseAPI {

    /**
     * Creates new page layout
     * Create a page layout
     */
    async createPageLayoutRaw(requestParameters: CreatePageLayoutRequest): Promise<runtime.ApiResponse<PageLayout>> {
        if (requestParameters.pageLayout === null || requestParameters.pageLayout === undefined) {
            throw new runtime.RequiredError('pageLayout','Required parameter requestParameters.pageLayout was null or undefined when calling createPageLayout.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/pageLayouts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PageLayoutToJSON(requestParameters.pageLayout),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageLayoutFromJSON(jsonValue));
    }

    /**
     * Creates new page layout
     * Create a page layout
     */
    async createPageLayout(requestParameters: CreatePageLayoutRequest): Promise<PageLayout> {
        const response = await this.createPageLayoutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delets page layout.
     * Deletes page layout.
     */
    async deletePageLayoutRaw(requestParameters: DeletePageLayoutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.pageLayoutId === null || requestParameters.pageLayoutId === undefined) {
            throw new runtime.RequiredError('pageLayoutId','Required parameter requestParameters.pageLayoutId was null or undefined when calling deletePageLayout.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/pageLayouts/{pageLayoutId}`.replace(`{${"pageLayoutId"}}`, encodeURIComponent(String(requestParameters.pageLayoutId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delets page layout.
     * Deletes page layout.
     */
    async deletePageLayout(requestParameters: DeletePageLayoutRequest): Promise<void> {
        await this.deletePageLayoutRaw(requestParameters);
    }

    /**
     * Finds a page layout by id
     * Find a page layout
     */
    async findPageLayoutRaw(requestParameters: FindPageLayoutRequest): Promise<runtime.ApiResponse<PageLayout>> {
        if (requestParameters.pageLayoutId === null || requestParameters.pageLayoutId === undefined) {
            throw new runtime.RequiredError('pageLayoutId','Required parameter requestParameters.pageLayoutId was null or undefined when calling findPageLayout.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/pageLayouts/{pageLayoutId}`.replace(`{${"pageLayoutId"}}`, encodeURIComponent(String(requestParameters.pageLayoutId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageLayoutFromJSON(jsonValue));
    }

    /**
     * Finds a page layout by id
     * Find a page layout
     */
    async findPageLayout(requestParameters: FindPageLayoutRequest): Promise<PageLayout> {
        const response = await this.findPageLayoutRaw(requestParameters);
        return await response.value();
    }

    /**
     * List page layouts
     * List page layouts
     */
    async listPageLayoutsRaw(requestParameters: ListPageLayoutsRequest): Promise<runtime.ApiResponse<Array<PageLayout>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.deviceModelId !== undefined) {
            queryParameters['deviceModelId'] = requestParameters.deviceModelId;
        }

        if (requestParameters.screenOrientation !== undefined) {
            queryParameters['screenOrientation'] = requestParameters.screenOrientation;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/pageLayouts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PageLayoutFromJSON));
    }

    /**
     * List page layouts
     * List page layouts
     */
    async listPageLayouts(requestParameters: ListPageLayoutsRequest): Promise<Array<PageLayout>> {
        const response = await this.listPageLayoutsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates page layout.
     * Updates page layout.
     */
    async updatePageLayoutRaw(requestParameters: UpdatePageLayoutRequest): Promise<runtime.ApiResponse<PageLayout>> {
        if (requestParameters.pageLayout === null || requestParameters.pageLayout === undefined) {
            throw new runtime.RequiredError('pageLayout','Required parameter requestParameters.pageLayout was null or undefined when calling updatePageLayout.');
        }

        if (requestParameters.pageLayoutId === null || requestParameters.pageLayoutId === undefined) {
            throw new runtime.RequiredError('pageLayoutId','Required parameter requestParameters.pageLayoutId was null or undefined when calling updatePageLayout.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/pageLayouts/{pageLayoutId}`.replace(`{${"pageLayoutId"}}`, encodeURIComponent(String(requestParameters.pageLayoutId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PageLayoutToJSON(requestParameters.pageLayout),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageLayoutFromJSON(jsonValue));
    }

    /**
     * Updates page layout.
     * Updates page layout.
     */
    async updatePageLayout(requestParameters: UpdatePageLayoutRequest): Promise<PageLayout> {
        const response = await this.updatePageLayoutRaw(requestParameters);
        return await response.value();
    }

}
