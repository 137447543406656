/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ExhibitionPageEventActionType {
    Hide = 'hide',
    Show = 'show',
    Setuservalue = 'setuservalue',
    Navigate = 'navigate',
    Setsrc = 'setsrc',
    Settext = 'settext',
    Triggerdevicegroupevent = 'triggerdevicegroupevent',
    ExecuteWebScript = 'executeWebScript',
    VisitorSessionEnd = 'visitorSessionEnd',
    StartVisitorSession = 'startVisitorSession'
}

export function ExhibitionPageEventActionTypeFromJSON(json: any): ExhibitionPageEventActionType {
    return ExhibitionPageEventActionTypeFromJSONTyped(json, false);
}

export function ExhibitionPageEventActionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExhibitionPageEventActionType {
    return json as ExhibitionPageEventActionType;
}

export function ExhibitionPageEventActionTypeToJSON(value?: ExhibitionPageEventActionType | null): any {
    return value as any;
}

