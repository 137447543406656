/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GroupContentVersionStatus,
    GroupContentVersionStatusFromJSON,
    GroupContentVersionStatusFromJSONTyped,
    GroupContentVersionStatusToJSON,
} from './';

/**
 * Content version (e.g. language) of a device group.
 * @export
 * @interface GroupContentVersion
 */
export interface GroupContentVersion {
    /**
     * 
     * @type {string}
     * @memberof GroupContentVersion
     */
    readonly id?: string;
    /**
     * Id of exhibition this group content version belongs to
     * @type {string}
     * @memberof GroupContentVersion
     */
    readonly exhibitionId?: string;
    /**
     * Human readable name of the group content version
     * @type {string}
     * @memberof GroupContentVersion
     */
    name: string;
    /**
     * 
     * @type {GroupContentVersionStatus}
     * @memberof GroupContentVersion
     */
    status: GroupContentVersionStatus;
    /**
     * Id of content version
     * @type {string}
     * @memberof GroupContentVersion
     */
    contentVersionId: string;
    /**
     * Id of device group
     * @type {string}
     * @memberof GroupContentVersion
     */
    deviceGroupId: string;
    /**
     * 
     * @type {string}
     * @memberof GroupContentVersion
     */
    readonly creatorId?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupContentVersion
     */
    readonly lastModifierId?: string;
    /**
     * Created date
     * @type {Date}
     * @memberof GroupContentVersion
     */
    readonly createdAt?: Date;
    /**
     * Date modified
     * @type {Date}
     * @memberof GroupContentVersion
     */
    readonly modifiedAt?: Date;
}

export function GroupContentVersionFromJSON(json: any): GroupContentVersion {
    return GroupContentVersionFromJSONTyped(json, false);
}

export function GroupContentVersionFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupContentVersion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'exhibitionId': !exists(json, 'exhibitionId') ? undefined : json['exhibitionId'],
        'name': json['name'],
        'status': GroupContentVersionStatusFromJSON(json['status']),
        'contentVersionId': json['contentVersionId'],
        'deviceGroupId': json['deviceGroupId'],
        'creatorId': !exists(json, 'creatorId') ? undefined : json['creatorId'],
        'lastModifierId': !exists(json, 'lastModifierId') ? undefined : json['lastModifierId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (new Date(json['modifiedAt'])),
    };
}

export function GroupContentVersionToJSON(value?: GroupContentVersion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'status': GroupContentVersionStatusToJSON(value.status),
        'contentVersionId': value.contentVersionId,
        'deviceGroupId': value.deviceGroupId,
    };
}


