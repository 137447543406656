/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StoredFile,
    StoredFileFromJSON,
    StoredFileToJSON,
} from '../models';

export interface DeleteStoredFileRequest {
    storedFileId: string;
}

export interface FindStoredFileRequest {
    storedFileId: string;
}

export interface ListStoredFilesRequest {
    folder: string;
}

export interface UpdateStoredFileRequest {
    storedFile: StoredFile;
    storedFileId: string;
}

/**
 * no description
 */
export class StoredFilesApi extends runtime.BaseAPI {

    /**
     * Deletes stored file
     * Deletes stored file
     */
    async deleteStoredFileRaw(requestParameters: DeleteStoredFileRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.storedFileId === null || requestParameters.storedFileId === undefined) {
            throw new runtime.RequiredError('storedFileId','Required parameter requestParameters.storedFileId was null or undefined when calling deleteStoredFile.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/storedFiles/{storedFileId}`.replace(`{${"storedFileId"}}`, encodeURIComponent(String(requestParameters.storedFileId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes stored file
     * Deletes stored file
     */
    async deleteStoredFile(requestParameters: DeleteStoredFileRequest): Promise<void> {
        await this.deleteStoredFileRaw(requestParameters);
    }

    /**
     * Find stored file
     * Find stored file
     */
    async findStoredFileRaw(requestParameters: FindStoredFileRequest): Promise<runtime.ApiResponse<StoredFile>> {
        if (requestParameters.storedFileId === null || requestParameters.storedFileId === undefined) {
            throw new runtime.RequiredError('storedFileId','Required parameter requestParameters.storedFileId was null or undefined when calling findStoredFile.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/storedFiles/{storedFileId}`.replace(`{${"storedFileId"}}`, encodeURIComponent(String(requestParameters.storedFileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StoredFileFromJSON(jsonValue));
    }

    /**
     * Find stored file
     * Find stored file
     */
    async findStoredFile(requestParameters: FindStoredFileRequest): Promise<StoredFile> {
        const response = await this.findStoredFileRaw(requestParameters);
        return await response.value();
    }

    /**
     * List stored files
     * List stored files
     */
    async listStoredFilesRaw(requestParameters: ListStoredFilesRequest): Promise<runtime.ApiResponse<Array<StoredFile>>> {
        if (requestParameters.folder === null || requestParameters.folder === undefined) {
            throw new runtime.RequiredError('folder','Required parameter requestParameters.folder was null or undefined when calling listStoredFiles.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.folder !== undefined) {
            queryParameters['folder'] = requestParameters.folder;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/storedFiles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StoredFileFromJSON));
    }

    /**
     * List stored files
     * List stored files
     */
    async listStoredFiles(requestParameters: ListStoredFilesRequest): Promise<Array<StoredFile>> {
        const response = await this.listStoredFilesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates stored file
     * Updates stored file
     */
    async updateStoredFileRaw(requestParameters: UpdateStoredFileRequest): Promise<runtime.ApiResponse<StoredFile>> {
        if (requestParameters.storedFile === null || requestParameters.storedFile === undefined) {
            throw new runtime.RequiredError('storedFile','Required parameter requestParameters.storedFile was null or undefined when calling updateStoredFile.');
        }

        if (requestParameters.storedFileId === null || requestParameters.storedFileId === undefined) {
            throw new runtime.RequiredError('storedFileId','Required parameter requestParameters.storedFileId was null or undefined when calling updateStoredFile.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/storedFiles/{storedFileId}`.replace(`{${"storedFileId"}}`, encodeURIComponent(String(requestParameters.storedFileId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StoredFileToJSON(requestParameters.storedFile),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StoredFileFromJSON(jsonValue));
    }

    /**
     * Updates stored file
     * Updates stored file
     */
    async updateStoredFile(requestParameters: UpdateStoredFileRequest): Promise<StoredFile> {
        const response = await this.updateStoredFileRaw(requestParameters);
        return await response.value();
    }

}
