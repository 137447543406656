/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Describes device model physical dimensions
 * @export
 * @interface DeviceModelDimensions
 */
export interface DeviceModelDimensions {
    /**
     * 
     * @type {number}
     * @memberof DeviceModelDimensions
     */
    deviceWidth?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceModelDimensions
     */
    deviceHeight?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceModelDimensions
     */
    deviceDepth?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceModelDimensions
     */
    screenWidth?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceModelDimensions
     */
    screenHeight?: number;
}

export function DeviceModelDimensionsFromJSON(json: any): DeviceModelDimensions {
    return DeviceModelDimensionsFromJSONTyped(json, false);
}

export function DeviceModelDimensionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceModelDimensions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceWidth': !exists(json, 'deviceWidth') ? undefined : json['deviceWidth'],
        'deviceHeight': !exists(json, 'deviceHeight') ? undefined : json['deviceHeight'],
        'deviceDepth': !exists(json, 'deviceDepth') ? undefined : json['deviceDepth'],
        'screenWidth': !exists(json, 'screenWidth') ? undefined : json['screenWidth'],
        'screenHeight': !exists(json, 'screenHeight') ? undefined : json['screenHeight'],
    };
}

export function DeviceModelDimensionsToJSON(value?: DeviceModelDimensions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deviceWidth': value.deviceWidth,
        'deviceHeight': value.deviceHeight,
        'deviceDepth': value.deviceDepth,
        'screenWidth': value.screenWidth,
        'screenHeight': value.screenHeight,
    };
}


