/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ExhibitionRoom,
    ExhibitionRoomFromJSON,
    ExhibitionRoomToJSON,
} from '../models';

export interface CreateExhibitionRoomRequest {
    exhibitionRoom: ExhibitionRoom;
    exhibitionId: string;
}

export interface DeleteExhibitionRoomRequest {
    exhibitionId: string;
    roomId: string;
}

export interface FindExhibitionRoomRequest {
    exhibitionId: string;
    roomId: string;
}

export interface ListExhibitionRoomsRequest {
    exhibitionId: string;
    floorId?: string;
}

export interface UpdateExhibitionRoomRequest {
    exhibitionRoom: ExhibitionRoom;
    exhibitionId: string;
    roomId: string;
}

/**
 * no description
 */
export class ExhibitionRoomsApi extends runtime.BaseAPI {

    /**
     * Creates new exhibition room
     * Create a room
     */
    async createExhibitionRoomRaw(requestParameters: CreateExhibitionRoomRequest): Promise<runtime.ApiResponse<ExhibitionRoom>> {
        if (requestParameters.exhibitionRoom === null || requestParameters.exhibitionRoom === undefined) {
            throw new runtime.RequiredError('exhibitionRoom','Required parameter requestParameters.exhibitionRoom was null or undefined when calling createExhibitionRoom.');
        }

        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling createExhibitionRoom.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/rooms`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExhibitionRoomToJSON(requestParameters.exhibitionRoom),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExhibitionRoomFromJSON(jsonValue));
    }

    /**
     * Creates new exhibition room
     * Create a room
     */
    async createExhibitionRoom(requestParameters: CreateExhibitionRoomRequest): Promise<ExhibitionRoom> {
        const response = await this.createExhibitionRoomRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delets exhibition room.
     * Deletes room.
     */
    async deleteExhibitionRoomRaw(requestParameters: DeleteExhibitionRoomRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling deleteExhibitionRoom.');
        }

        if (requestParameters.roomId === null || requestParameters.roomId === undefined) {
            throw new runtime.RequiredError('roomId','Required parameter requestParameters.roomId was null or undefined when calling deleteExhibitionRoom.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/rooms/{roomId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))).replace(`{${"roomId"}}`, encodeURIComponent(String(requestParameters.roomId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delets exhibition room.
     * Deletes room.
     */
    async deleteExhibitionRoom(requestParameters: DeleteExhibitionRoomRequest): Promise<void> {
        await this.deleteExhibitionRoomRaw(requestParameters);
    }

    /**
     * Finds a room by id
     * Find a room
     */
    async findExhibitionRoomRaw(requestParameters: FindExhibitionRoomRequest): Promise<runtime.ApiResponse<ExhibitionRoom>> {
        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling findExhibitionRoom.');
        }

        if (requestParameters.roomId === null || requestParameters.roomId === undefined) {
            throw new runtime.RequiredError('roomId','Required parameter requestParameters.roomId was null or undefined when calling findExhibitionRoom.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/rooms/{roomId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))).replace(`{${"roomId"}}`, encodeURIComponent(String(requestParameters.roomId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExhibitionRoomFromJSON(jsonValue));
    }

    /**
     * Finds a room by id
     * Find a room
     */
    async findExhibitionRoom(requestParameters: FindExhibitionRoomRequest): Promise<ExhibitionRoom> {
        const response = await this.findExhibitionRoomRaw(requestParameters);
        return await response.value();
    }

    /**
     * List exhibition rooms
     * List rooms
     */
    async listExhibitionRoomsRaw(requestParameters: ListExhibitionRoomsRequest): Promise<runtime.ApiResponse<Array<ExhibitionRoom>>> {
        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling listExhibitionRooms.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.floorId !== undefined) {
            queryParameters['floorId'] = requestParameters.floorId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/rooms`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExhibitionRoomFromJSON));
    }

    /**
     * List exhibition rooms
     * List rooms
     */
    async listExhibitionRooms(requestParameters: ListExhibitionRoomsRequest): Promise<Array<ExhibitionRoom>> {
        const response = await this.listExhibitionRoomsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates room.
     * Updates room.
     */
    async updateExhibitionRoomRaw(requestParameters: UpdateExhibitionRoomRequest): Promise<runtime.ApiResponse<ExhibitionRoom>> {
        if (requestParameters.exhibitionRoom === null || requestParameters.exhibitionRoom === undefined) {
            throw new runtime.RequiredError('exhibitionRoom','Required parameter requestParameters.exhibitionRoom was null or undefined when calling updateExhibitionRoom.');
        }

        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling updateExhibitionRoom.');
        }

        if (requestParameters.roomId === null || requestParameters.roomId === undefined) {
            throw new runtime.RequiredError('roomId','Required parameter requestParameters.roomId was null or undefined when calling updateExhibitionRoom.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/rooms/{roomId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))).replace(`{${"roomId"}}`, encodeURIComponent(String(requestParameters.roomId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ExhibitionRoomToJSON(requestParameters.exhibitionRoom),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExhibitionRoomFromJSON(jsonValue));
    }

    /**
     * Updates room.
     * Updates room.
     */
    async updateExhibitionRoom(requestParameters: UpdateExhibitionRoomRequest): Promise<ExhibitionRoom> {
        const response = await this.updateExhibitionRoomRaw(requestParameters);
        return await response.value();
    }

}
