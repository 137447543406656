import { createStyles } from "@material-ui/core";

export default createStyles({

  list: {
    minWidth: 250,
    maxWidth: 250,
    padding: 0
  },

  listItem: {
    height: 70
  }

});