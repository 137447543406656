/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ExhibitionPage,
    ExhibitionPageFromJSON,
    ExhibitionPageToJSON,
} from '../models';

export interface CreateExhibitionPageRequest {
    exhibitionPage: ExhibitionPage;
    exhibitionId: string;
}

export interface DeleteExhibitionPageRequest {
    exhibitionId: string;
    pageId: string;
}

export interface FindExhibitionPageRequest {
    exhibitionId: string;
    pageId: string;
}

export interface ListExhibitionPagesRequest {
    exhibitionId: string;
    contentVersionId?: string;
    exhibitionDeviceId?: string;
    pageLayoutId?: string;
}

export interface UpdateExhibitionPageRequest {
    exhibitionPage: ExhibitionPage;
    exhibitionId: string;
    pageId: string;
}

/**
 * no description
 */
export class ExhibitionPagesApi extends runtime.BaseAPI {

    /**
     * Creates new exhibition page
     * Create a page
     */
    async createExhibitionPageRaw(requestParameters: CreateExhibitionPageRequest): Promise<runtime.ApiResponse<ExhibitionPage>> {
        if (requestParameters.exhibitionPage === null || requestParameters.exhibitionPage === undefined) {
            throw new runtime.RequiredError('exhibitionPage','Required parameter requestParameters.exhibitionPage was null or undefined when calling createExhibitionPage.');
        }

        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling createExhibitionPage.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/pages`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExhibitionPageToJSON(requestParameters.exhibitionPage),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExhibitionPageFromJSON(jsonValue));
    }

    /**
     * Creates new exhibition page
     * Create a page
     */
    async createExhibitionPage(requestParameters: CreateExhibitionPageRequest): Promise<ExhibitionPage> {
        const response = await this.createExhibitionPageRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delets exhibition page.
     * Deletes page.
     */
    async deleteExhibitionPageRaw(requestParameters: DeleteExhibitionPageRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling deleteExhibitionPage.');
        }

        if (requestParameters.pageId === null || requestParameters.pageId === undefined) {
            throw new runtime.RequiredError('pageId','Required parameter requestParameters.pageId was null or undefined when calling deleteExhibitionPage.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/pages/{pageId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))).replace(`{${"pageId"}}`, encodeURIComponent(String(requestParameters.pageId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delets exhibition page.
     * Deletes page.
     */
    async deleteExhibitionPage(requestParameters: DeleteExhibitionPageRequest): Promise<void> {
        await this.deleteExhibitionPageRaw(requestParameters);
    }

    /**
     * Finds a page by id
     * Find a page
     */
    async findExhibitionPageRaw(requestParameters: FindExhibitionPageRequest): Promise<runtime.ApiResponse<ExhibitionPage>> {
        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling findExhibitionPage.');
        }

        if (requestParameters.pageId === null || requestParameters.pageId === undefined) {
            throw new runtime.RequiredError('pageId','Required parameter requestParameters.pageId was null or undefined when calling findExhibitionPage.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/pages/{pageId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))).replace(`{${"pageId"}}`, encodeURIComponent(String(requestParameters.pageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExhibitionPageFromJSON(jsonValue));
    }

    /**
     * Finds a page by id
     * Find a page
     */
    async findExhibitionPage(requestParameters: FindExhibitionPageRequest): Promise<ExhibitionPage> {
        const response = await this.findExhibitionPageRaw(requestParameters);
        return await response.value();
    }

    /**
     * List exhibition pages
     * List pages
     */
    async listExhibitionPagesRaw(requestParameters: ListExhibitionPagesRequest): Promise<runtime.ApiResponse<Array<ExhibitionPage>>> {
        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling listExhibitionPages.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.contentVersionId !== undefined) {
            queryParameters['contentVersionId'] = requestParameters.contentVersionId;
        }

        if (requestParameters.exhibitionDeviceId !== undefined) {
            queryParameters['exhibitionDeviceId'] = requestParameters.exhibitionDeviceId;
        }

        if (requestParameters.pageLayoutId !== undefined) {
            queryParameters['pageLayoutId'] = requestParameters.pageLayoutId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/pages`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExhibitionPageFromJSON));
    }

    /**
     * List exhibition pages
     * List pages
     */
    async listExhibitionPages(requestParameters: ListExhibitionPagesRequest): Promise<Array<ExhibitionPage>> {
        const response = await this.listExhibitionPagesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates page.
     * Updates page.
     */
    async updateExhibitionPageRaw(requestParameters: UpdateExhibitionPageRequest): Promise<runtime.ApiResponse<ExhibitionPage>> {
        if (requestParameters.exhibitionPage === null || requestParameters.exhibitionPage === undefined) {
            throw new runtime.RequiredError('exhibitionPage','Required parameter requestParameters.exhibitionPage was null or undefined when calling updateExhibitionPage.');
        }

        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling updateExhibitionPage.');
        }

        if (requestParameters.pageId === null || requestParameters.pageId === undefined) {
            throw new runtime.RequiredError('pageId','Required parameter requestParameters.pageId was null or undefined when calling updateExhibitionPage.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/pages/{pageId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))).replace(`{${"pageId"}}`, encodeURIComponent(String(requestParameters.pageId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ExhibitionPageToJSON(requestParameters.exhibitionPage),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExhibitionPageFromJSON(jsonValue));
    }

    /**
     * Updates page.
     * Updates page.
     */
    async updateExhibitionPage(requestParameters: UpdateExhibitionPageRequest): Promise<ExhibitionPage> {
        const response = await this.updateExhibitionPageRaw(requestParameters);
        return await response.value();
    }

}
