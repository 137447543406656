/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExhibitionPageEventTrigger,
    ExhibitionPageEventTriggerFromJSON,
    ExhibitionPageEventTriggerFromJSONTyped,
    ExhibitionPageEventTriggerToJSON,
    ExhibitionPageResource,
    ExhibitionPageResourceFromJSON,
    ExhibitionPageResourceFromJSONTyped,
    ExhibitionPageResourceToJSON,
    ExhibitionPageTransition,
    ExhibitionPageTransitionFromJSON,
    ExhibitionPageTransitionFromJSONTyped,
    ExhibitionPageTransitionToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExhibitionPage
 */
export interface ExhibitionPage {
    /**
     * 
     * @type {string}
     * @memberof ExhibitionPage
     */
    readonly id?: string;
    /**
     * Id of exhibition this page belongs to
     * @type {string}
     * @memberof ExhibitionPage
     */
    readonly exhibitionId?: string;
    /**
     * Id of device this page belongs to
     * @type {string}
     * @memberof ExhibitionPage
     */
    deviceId: string;
    /**
     * Id of page layout
     * @type {string}
     * @memberof ExhibitionPage
     */
    layoutId: string;
    /**
     * Id of content version
     * @type {string}
     * @memberof ExhibitionPage
     */
    contentVersionId: string;
    /**
     * Human readable name for the layout
     * @type {string}
     * @memberof ExhibitionPage
     */
    name: string;
    /**
     * 
     * @type {Array<ExhibitionPageResource>}
     * @memberof ExhibitionPage
     */
    resources: Array<ExhibitionPageResource>;
    /**
     * 
     * @type {Array<ExhibitionPageEventTrigger>}
     * @memberof ExhibitionPage
     */
    eventTriggers: Array<ExhibitionPageEventTrigger>;
    /**
     * 
     * @type {Array<ExhibitionPageTransition>}
     * @memberof ExhibitionPage
     */
    enterTransitions: Array<ExhibitionPageTransition>;
    /**
     * 
     * @type {Array<ExhibitionPageTransition>}
     * @memberof ExhibitionPage
     */
    exitTransitions: Array<ExhibitionPageTransition>;
    /**
     * 
     * @type {number}
     * @memberof ExhibitionPage
     */
    orderNumber: number;
    /**
     * 
     * @type {string}
     * @memberof ExhibitionPage
     */
    readonly creatorId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExhibitionPage
     */
    readonly lastModifierId?: string;
    /**
     * Created date
     * @type {Date}
     * @memberof ExhibitionPage
     */
    readonly createdAt?: Date;
    /**
     * Date modified
     * @type {Date}
     * @memberof ExhibitionPage
     */
    readonly modifiedAt?: Date;
}

export function ExhibitionPageFromJSON(json: any): ExhibitionPage {
    return ExhibitionPageFromJSONTyped(json, false);
}

export function ExhibitionPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExhibitionPage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'exhibitionId': !exists(json, 'exhibitionId') ? undefined : json['exhibitionId'],
        'deviceId': json['deviceId'],
        'layoutId': json['layoutId'],
        'contentVersionId': json['contentVersionId'],
        'name': json['name'],
        'resources': ((json['resources'] as Array<any>).map(ExhibitionPageResourceFromJSON)),
        'eventTriggers': ((json['eventTriggers'] as Array<any>).map(ExhibitionPageEventTriggerFromJSON)),
        'enterTransitions': ((json['enterTransitions'] as Array<any>).map(ExhibitionPageTransitionFromJSON)),
        'exitTransitions': ((json['exitTransitions'] as Array<any>).map(ExhibitionPageTransitionFromJSON)),
        'orderNumber': json['orderNumber'],
        'creatorId': !exists(json, 'creatorId') ? undefined : json['creatorId'],
        'lastModifierId': !exists(json, 'lastModifierId') ? undefined : json['lastModifierId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (new Date(json['modifiedAt'])),
    };
}

export function ExhibitionPageToJSON(value?: ExhibitionPage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deviceId': value.deviceId,
        'layoutId': value.layoutId,
        'contentVersionId': value.contentVersionId,
        'name': value.name,
        'resources': ((value.resources as Array<any>).map(ExhibitionPageResourceToJSON)),
        'eventTriggers': ((value.eventTriggers as Array<any>).map(ExhibitionPageEventTriggerToJSON)),
        'enterTransitions': ((value.enterTransitions as Array<any>).map(ExhibitionPageTransitionToJSON)),
        'exitTransitions': ((value.exitTransitions as Array<any>).map(ExhibitionPageTransitionToJSON)),
        'orderNumber': value.orderNumber,
    };
}


