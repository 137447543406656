/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Animation,
    AnimationFromJSON,
    AnimationFromJSONTyped,
    AnimationToJSON,
    AnimationTimeInterpolation,
    AnimationTimeInterpolationFromJSON,
    AnimationTimeInterpolationFromJSONTyped,
    AnimationTimeInterpolationToJSON,
} from './';

/**
 * 
 * @export
 * @interface Transition
 */
export interface Transition {
    /**
     * 
     * @type {Animation}
     * @memberof Transition
     */
    animation: Animation;
    /**
     * 
     * @type {AnimationTimeInterpolation}
     * @memberof Transition
     */
    timeInterpolation: AnimationTimeInterpolation;
    /**
     * 
     * @type {number}
     * @memberof Transition
     */
    duration: number;
}

export function TransitionFromJSON(json: any): Transition {
    return TransitionFromJSONTyped(json, false);
}

export function TransitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Transition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'animation': AnimationFromJSON(json['animation']),
        'timeInterpolation': AnimationTimeInterpolationFromJSON(json['timeInterpolation']),
        'duration': json['duration'],
    };
}

export function TransitionToJSON(value?: Transition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'animation': AnimationToJSON(value.animation),
        'timeInterpolation': AnimationTimeInterpolationToJSON(value.timeInterpolation),
        'duration': value.duration,
    };
}


