/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ExhibitionFloor,
    ExhibitionFloorFromJSON,
    ExhibitionFloorToJSON,
} from '../models';

export interface CreateExhibitionFloorRequest {
    exhibitionFloor: ExhibitionFloor;
    exhibitionId: string;
}

export interface DeleteExhibitionFloorRequest {
    exhibitionId: string;
    floorId: string;
}

export interface FindExhibitionFloorRequest {
    exhibitionId: string;
    floorId: string;
}

export interface ListExhibitionFloorsRequest {
    exhibitionId: string;
}

export interface UpdateExhibitionFloorRequest {
    exhibitionFloor: ExhibitionFloor;
    exhibitionId: string;
    floorId: string;
}

/**
 * no description
 */
export class ExhibitionFloorsApi extends runtime.BaseAPI {

    /**
     * Creates new exhibition floor
     * Create a floor
     */
    async createExhibitionFloorRaw(requestParameters: CreateExhibitionFloorRequest): Promise<runtime.ApiResponse<ExhibitionFloor>> {
        if (requestParameters.exhibitionFloor === null || requestParameters.exhibitionFloor === undefined) {
            throw new runtime.RequiredError('exhibitionFloor','Required parameter requestParameters.exhibitionFloor was null or undefined when calling createExhibitionFloor.');
        }

        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling createExhibitionFloor.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/floors`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExhibitionFloorToJSON(requestParameters.exhibitionFloor),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExhibitionFloorFromJSON(jsonValue));
    }

    /**
     * Creates new exhibition floor
     * Create a floor
     */
    async createExhibitionFloor(requestParameters: CreateExhibitionFloorRequest): Promise<ExhibitionFloor> {
        const response = await this.createExhibitionFloorRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delets exhibition floor.
     * Deletes floor.
     */
    async deleteExhibitionFloorRaw(requestParameters: DeleteExhibitionFloorRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling deleteExhibitionFloor.');
        }

        if (requestParameters.floorId === null || requestParameters.floorId === undefined) {
            throw new runtime.RequiredError('floorId','Required parameter requestParameters.floorId was null or undefined when calling deleteExhibitionFloor.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/floors/{floorId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))).replace(`{${"floorId"}}`, encodeURIComponent(String(requestParameters.floorId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delets exhibition floor.
     * Deletes floor.
     */
    async deleteExhibitionFloor(requestParameters: DeleteExhibitionFloorRequest): Promise<void> {
        await this.deleteExhibitionFloorRaw(requestParameters);
    }

    /**
     * Finds a floor by id
     * Find a floor
     */
    async findExhibitionFloorRaw(requestParameters: FindExhibitionFloorRequest): Promise<runtime.ApiResponse<ExhibitionFloor>> {
        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling findExhibitionFloor.');
        }

        if (requestParameters.floorId === null || requestParameters.floorId === undefined) {
            throw new runtime.RequiredError('floorId','Required parameter requestParameters.floorId was null or undefined when calling findExhibitionFloor.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/floors/{floorId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))).replace(`{${"floorId"}}`, encodeURIComponent(String(requestParameters.floorId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExhibitionFloorFromJSON(jsonValue));
    }

    /**
     * Finds a floor by id
     * Find a floor
     */
    async findExhibitionFloor(requestParameters: FindExhibitionFloorRequest): Promise<ExhibitionFloor> {
        const response = await this.findExhibitionFloorRaw(requestParameters);
        return await response.value();
    }

    /**
     * List exhibition floors
     * List floors
     */
    async listExhibitionFloorsRaw(requestParameters: ListExhibitionFloorsRequest): Promise<runtime.ApiResponse<Array<ExhibitionFloor>>> {
        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling listExhibitionFloors.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/floors`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExhibitionFloorFromJSON));
    }

    /**
     * List exhibition floors
     * List floors
     */
    async listExhibitionFloors(requestParameters: ListExhibitionFloorsRequest): Promise<Array<ExhibitionFloor>> {
        const response = await this.listExhibitionFloorsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates floor.
     * Updates floor.
     */
    async updateExhibitionFloorRaw(requestParameters: UpdateExhibitionFloorRequest): Promise<runtime.ApiResponse<ExhibitionFloor>> {
        if (requestParameters.exhibitionFloor === null || requestParameters.exhibitionFloor === undefined) {
            throw new runtime.RequiredError('exhibitionFloor','Required parameter requestParameters.exhibitionFloor was null or undefined when calling updateExhibitionFloor.');
        }

        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling updateExhibitionFloor.');
        }

        if (requestParameters.floorId === null || requestParameters.floorId === undefined) {
            throw new runtime.RequiredError('floorId','Required parameter requestParameters.floorId was null or undefined when calling updateExhibitionFloor.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/floors/{floorId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))).replace(`{${"floorId"}}`, encodeURIComponent(String(requestParameters.floorId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ExhibitionFloorToJSON(requestParameters.exhibitionFloor),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExhibitionFloorFromJSON(jsonValue));
    }

    /**
     * Updates floor.
     * Updates floor.
     */
    async updateExhibitionFloor(requestParameters: UpdateExhibitionFloorRequest): Promise<ExhibitionFloor> {
        const response = await this.updateExhibitionFloorRaw(requestParameters);
        return await response.value();
    }

}
