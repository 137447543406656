/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VisitorVariableType,
    VisitorVariableTypeFromJSON,
    VisitorVariableTypeFromJSONTyped,
    VisitorVariableTypeToJSON,
} from './';

/**
 * Metadata for visitor session variable
 * @export
 * @interface VisitorVariable
 */
export interface VisitorVariable {
    /**
     * 
     * @type {string}
     * @memberof VisitorVariable
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof VisitorVariable
     */
    readonly exhibitionId?: string;
    /**
     * 
     * @type {string}
     * @memberof VisitorVariable
     */
    name: string;
    /**
     * 
     * @type {VisitorVariableType}
     * @memberof VisitorVariable
     */
    type: VisitorVariableType;
    /**
     * Whether the visitor variable should be editable from the customer service UI
     * @type {boolean}
     * @memberof VisitorVariable
     */
    editableFromUI: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof VisitorVariable
     */
    _enum?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof VisitorVariable
     */
    readonly creatorId?: string;
    /**
     * 
     * @type {string}
     * @memberof VisitorVariable
     */
    readonly lastModifierId?: string;
    /**
     * Created date
     * @type {Date}
     * @memberof VisitorVariable
     */
    readonly createdAt?: Date;
    /**
     * Date modified
     * @type {Date}
     * @memberof VisitorVariable
     */
    readonly modifiedAt?: Date;
}

export function VisitorVariableFromJSON(json: any): VisitorVariable {
    return VisitorVariableFromJSONTyped(json, false);
}

export function VisitorVariableFromJSONTyped(json: any, ignoreDiscriminator: boolean): VisitorVariable {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'exhibitionId': !exists(json, 'exhibitionId') ? undefined : json['exhibitionId'],
        'name': json['name'],
        'type': VisitorVariableTypeFromJSON(json['type']),
        'editableFromUI': json['editableFromUI'],
        '_enum': !exists(json, 'enum') ? undefined : json['enum'],
        'creatorId': !exists(json, 'creatorId') ? undefined : json['creatorId'],
        'lastModifierId': !exists(json, 'lastModifierId') ? undefined : json['lastModifierId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (new Date(json['modifiedAt'])),
    };
}

export function VisitorVariableToJSON(value?: VisitorVariable | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': VisitorVariableTypeToJSON(value.type),
        'editableFromUI': value.editableFromUI,
        'enum': value._enum,
    };
}


