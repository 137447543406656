/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Visitor,
    VisitorFromJSON,
    VisitorToJSON,
    VisitorTag,
    VisitorTagFromJSON,
    VisitorTagToJSON,
} from '../models';

export interface CreateVisitorRequest {
    visitor: Visitor;
    exhibitionId: string;
}

export interface DeleteVisitorRequest {
    exhibitionId: string;
    visitorId: string;
}

export interface FindVisitorRequest {
    exhibitionId: string;
    visitorId: string;
}

export interface FindVisitorTagRequest {
    exhibitionId: string;
    tagId: string;
}

export interface ListVisitorsRequest {
    exhibitionId: string;
    tagId?: string;
    email?: string;
}

export interface UpdateVisitorRequest {
    visitor: Visitor;
    exhibitionId: string;
    visitorId: string;
}

/**
 * no description
 */
export class VisitorsApi extends runtime.BaseAPI {

    /**
     * Creates a new exhibition visitor
     * Create a visitor
     */
    async createVisitorRaw(requestParameters: CreateVisitorRequest): Promise<runtime.ApiResponse<Visitor>> {
        if (requestParameters.visitor === null || requestParameters.visitor === undefined) {
            throw new runtime.RequiredError('visitor','Required parameter requestParameters.visitor was null or undefined when calling createVisitor.');
        }

        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling createVisitor.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/visitor`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VisitorToJSON(requestParameters.visitor),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VisitorFromJSON(jsonValue));
    }

    /**
     * Creates a new exhibition visitor
     * Create a visitor
     */
    async createVisitor(requestParameters: CreateVisitorRequest): Promise<Visitor> {
        const response = await this.createVisitorRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes an exhibition visitor.
     * Deletes a visitor.
     */
    async deleteVisitorRaw(requestParameters: DeleteVisitorRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling deleteVisitor.');
        }

        if (requestParameters.visitorId === null || requestParameters.visitorId === undefined) {
            throw new runtime.RequiredError('visitorId','Required parameter requestParameters.visitorId was null or undefined when calling deleteVisitor.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/visitors/{visitorId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))).replace(`{${"visitorId"}}`, encodeURIComponent(String(requestParameters.visitorId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an exhibition visitor.
     * Deletes a visitor.
     */
    async deleteVisitor(requestParameters: DeleteVisitorRequest): Promise<void> {
        await this.deleteVisitorRaw(requestParameters);
    }

    /**
     * Finds a visitor by id
     * Find a visitor
     */
    async findVisitorRaw(requestParameters: FindVisitorRequest): Promise<runtime.ApiResponse<Visitor>> {
        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling findVisitor.');
        }

        if (requestParameters.visitorId === null || requestParameters.visitorId === undefined) {
            throw new runtime.RequiredError('visitorId','Required parameter requestParameters.visitorId was null or undefined when calling findVisitor.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/visitors/{visitorId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))).replace(`{${"visitorId"}}`, encodeURIComponent(String(requestParameters.visitorId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VisitorFromJSON(jsonValue));
    }

    /**
     * Finds a visitor by id
     * Find a visitor
     */
    async findVisitor(requestParameters: FindVisitorRequest): Promise<Visitor> {
        const response = await this.findVisitorRaw(requestParameters);
        return await response.value();
    }

    /**
     * Finds a visitor tag by tagId
     * Find a visitor tag
     */
    async findVisitorTagRaw(requestParameters: FindVisitorTagRequest): Promise<runtime.ApiResponse<VisitorTag>> {
        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling findVisitorTag.');
        }

        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId','Required parameter requestParameters.tagId was null or undefined when calling findVisitorTag.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/visitorTags/{tagId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))).replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VisitorTagFromJSON(jsonValue));
    }

    /**
     * Finds a visitor tag by tagId
     * Find a visitor tag
     */
    async findVisitorTag(requestParameters: FindVisitorTagRequest): Promise<VisitorTag> {
        const response = await this.findVisitorTagRaw(requestParameters);
        return await response.value();
    }

    /**
     * List exhibition visitors
     * List visitors
     */
    async listVisitorsRaw(requestParameters: ListVisitorsRequest): Promise<runtime.ApiResponse<Array<Visitor>>> {
        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling listVisitors.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.tagId !== undefined) {
            queryParameters['tagId'] = requestParameters.tagId;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/visitor`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VisitorFromJSON));
    }

    /**
     * List exhibition visitors
     * List visitors
     */
    async listVisitors(requestParameters: ListVisitorsRequest): Promise<Array<Visitor>> {
        const response = await this.listVisitorsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates a visitor.
     * Updates a visitor.
     */
    async updateVisitorRaw(requestParameters: UpdateVisitorRequest): Promise<runtime.ApiResponse<Visitor>> {
        if (requestParameters.visitor === null || requestParameters.visitor === undefined) {
            throw new runtime.RequiredError('visitor','Required parameter requestParameters.visitor was null or undefined when calling updateVisitor.');
        }

        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling updateVisitor.');
        }

        if (requestParameters.visitorId === null || requestParameters.visitorId === undefined) {
            throw new runtime.RequiredError('visitorId','Required parameter requestParameters.visitorId was null or undefined when calling updateVisitor.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/visitors/{visitorId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))).replace(`{${"visitorId"}}`, encodeURIComponent(String(requestParameters.visitorId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: VisitorToJSON(requestParameters.visitor),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VisitorFromJSON(jsonValue));
    }

    /**
     * Updates a visitor.
     * Updates a visitor.
     */
    async updateVisitor(requestParameters: UpdateVisitorRequest): Promise<Visitor> {
        const response = await this.updateVisitorRaw(requestParameters);
        return await response.value();
    }

}
