/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VisitorSessionVisitedDeviceGroup
 */
export interface VisitorSessionVisitedDeviceGroup {
    /**
     * 
     * @type {string}
     * @memberof VisitorSessionVisitedDeviceGroup
     */
    deviceGroupId: string;
    /**
     * Time visitor session entered the device group
     * @type {Date}
     * @memberof VisitorSessionVisitedDeviceGroup
     */
    enteredAt?: Date;
    /**
     * Time visitor session exited the device group
     * @type {Date}
     * @memberof VisitorSessionVisitedDeviceGroup
     */
    exitedAt?: Date;
}

export function VisitorSessionVisitedDeviceGroupFromJSON(json: any): VisitorSessionVisitedDeviceGroup {
    return VisitorSessionVisitedDeviceGroupFromJSONTyped(json, false);
}

export function VisitorSessionVisitedDeviceGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): VisitorSessionVisitedDeviceGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceGroupId': json['deviceGroupId'],
        'enteredAt': !exists(json, 'enteredAt') ? undefined : (new Date(json['enteredAt'])),
        'exitedAt': !exists(json, 'exitedAt') ? undefined : (new Date(json['exitedAt'])),
    };
}

export function VisitorSessionVisitedDeviceGroupToJSON(value?: VisitorSessionVisitedDeviceGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deviceGroupId': value.deviceGroupId,
        'enteredAt': value.enteredAt === undefined ? undefined : (value.enteredAt.toISOString()),
        'exitedAt': value.exitedAt === undefined ? undefined : (value.exitedAt.toISOString()),
    };
}


