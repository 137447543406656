/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PageLayoutView,
    PageLayoutViewFromJSON,
    PageLayoutViewFromJSONTyped,
    PageLayoutViewToJSON,
} from './';

/**
 * Describes a sub layout for a page
 * @export
 * @interface SubLayout
 */
export interface SubLayout {
    /**
     * 
     * @type {string}
     * @memberof SubLayout
     */
    readonly id?: string;
    /**
     * Human readable name for the sub layout
     * @type {string}
     * @memberof SubLayout
     */
    name: string;
    /**
     * 
     * @type {PageLayoutView}
     * @memberof SubLayout
     */
    data: PageLayoutView;
    /**
     * 
     * @type {string}
     * @memberof SubLayout
     */
    readonly creatorId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubLayout
     */
    readonly lastModifierId?: string;
    /**
     * Created date
     * @type {Date}
     * @memberof SubLayout
     */
    readonly createdAt?: Date;
    /**
     * Date modified
     * @type {Date}
     * @memberof SubLayout
     */
    readonly modifiedAt?: Date;
}

export function SubLayoutFromJSON(json: any): SubLayout {
    return SubLayoutFromJSONTyped(json, false);
}

export function SubLayoutFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubLayout {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'data': PageLayoutViewFromJSON(json['data']),
        'creatorId': !exists(json, 'creatorId') ? undefined : json['creatorId'],
        'lastModifierId': !exists(json, 'lastModifierId') ? undefined : json['lastModifierId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (new Date(json['modifiedAt'])),
    };
}

export function SubLayoutToJSON(value?: SubLayout | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'data': PageLayoutViewToJSON(value.data),
    };
}


