import { createStyles } from "@material-ui/core";

export default createStyles({

  root: {
    position: "relative",
    gridArea: "timeline",
    overflow: "hidden",
    minHeight: 250
  },

});