/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ExhibitionPageResourceType {
    Text = 'text',
    Image = 'image',
    Video = 'video',
    Html = 'html',
    Svg = 'svg'
}

export function ExhibitionPageResourceTypeFromJSON(json: any): ExhibitionPageResourceType {
    return ExhibitionPageResourceTypeFromJSONTyped(json, false);
}

export function ExhibitionPageResourceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExhibitionPageResourceType {
    return json as ExhibitionPageResourceType;
}

export function ExhibitionPageResourceTypeToJSON(value?: ExhibitionPageResourceType | null): any {
    return value as any;
}

