/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * GeoJson polygon object
 * @export
 * @interface Polygon
 */
export interface Polygon {
    /**
     * 
     * @type {string}
     * @memberof Polygon
     */
    type?: string;
    /**
     * Polygon shapes
     * @type {Array<Array<Array<number>>>}
     * @memberof Polygon
     */
    coordinates?: Array<Array<Array<number>>>;
}

export function PolygonFromJSON(json: any): Polygon {
    return PolygonFromJSONTyped(json, false);
}

export function PolygonFromJSONTyped(json: any, ignoreDiscriminator: boolean): Polygon {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'coordinates': !exists(json, 'coordinates') ? undefined : json['coordinates'],
    };
}

export function PolygonToJSON(value?: Polygon | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'coordinates': value.coordinates,
    };
}


