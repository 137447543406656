/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Exhibition,
    ExhibitionFromJSON,
    ExhibitionToJSON,
} from '../models';

export interface CreateExhibitionRequest {
    exhibition?: Exhibition;
    sourceExhibitionId?: string;
}

export interface DeleteExhibitionRequest {
    exhibitionId: string;
}

export interface FindExhibitionRequest {
    exhibitionId: string;
}

export interface UpdateExhibitionRequest {
    exhibition: Exhibition;
    exhibitionId: string;
}

/**
 * no description
 */
export class ExhibitionsApi extends runtime.BaseAPI {

    /**
     * Creates new exhibition
     * Create a exhibition.
     */
    async createExhibitionRaw(requestParameters: CreateExhibitionRequest): Promise<runtime.ApiResponse<Exhibition>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.sourceExhibitionId !== undefined) {
            queryParameters['sourceExhibitionId'] = requestParameters.sourceExhibitionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExhibitionToJSON(requestParameters.exhibition),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExhibitionFromJSON(jsonValue));
    }

    /**
     * Creates new exhibition
     * Create a exhibition.
     */
    async createExhibition(requestParameters: CreateExhibitionRequest): Promise<Exhibition> {
        const response = await this.createExhibitionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delets exhibition
     * Deletes exhibition.
     */
    async deleteExhibitionRaw(requestParameters: DeleteExhibitionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling deleteExhibition.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delets exhibition
     * Deletes exhibition.
     */
    async deleteExhibition(requestParameters: DeleteExhibitionRequest): Promise<void> {
        await this.deleteExhibitionRaw(requestParameters);
    }

    /**
     * Finds a exhibition by id
     * Find a exhibition.
     */
    async findExhibitionRaw(requestParameters: FindExhibitionRequest): Promise<runtime.ApiResponse<Exhibition>> {
        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling findExhibition.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExhibitionFromJSON(jsonValue));
    }

    /**
     * Finds a exhibition by id
     * Find a exhibition.
     */
    async findExhibition(requestParameters: FindExhibitionRequest): Promise<Exhibition> {
        const response = await this.findExhibitionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists a exhibition
     * List exhibitions.
     */
    async listExhibitionsRaw(): Promise<runtime.ApiResponse<Array<Exhibition>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExhibitionFromJSON));
    }

    /**
     * Lists a exhibition
     * List exhibitions.
     */
    async listExhibitions(): Promise<Array<Exhibition>> {
        const response = await this.listExhibitionsRaw();
        return await response.value();
    }

    /**
     * Updates exhibition
     * Updates exhibition.
     */
    async updateExhibitionRaw(requestParameters: UpdateExhibitionRequest): Promise<runtime.ApiResponse<Exhibition>> {
        if (requestParameters.exhibition === null || requestParameters.exhibition === undefined) {
            throw new runtime.RequiredError('exhibition','Required parameter requestParameters.exhibition was null or undefined when calling updateExhibition.');
        }

        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling updateExhibition.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ExhibitionToJSON(requestParameters.exhibition),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExhibitionFromJSON(jsonValue));
    }

    /**
     * Updates exhibition
     * Updates exhibition.
     */
    async updateExhibition(requestParameters: UpdateExhibitionRequest): Promise<Exhibition> {
        const response = await this.updateExhibitionRaw(requestParameters);
        return await response.value();
    }

}
