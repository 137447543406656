/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Polygon,
    PolygonFromJSON,
    PolygonFromJSONTyped,
    PolygonToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExhibitionRoom
 */
export interface ExhibitionRoom {
    /**
     * 
     * @type {string}
     * @memberof ExhibitionRoom
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExhibitionRoom
     */
    readonly exhibitionId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExhibitionRoom
     */
    floorId: string;
    /**
     * 
     * @type {string}
     * @memberof ExhibitionRoom
     */
    name: string;
    /**
     * Room\'s color in CSS -color format
     * @type {string}
     * @memberof ExhibitionRoom
     */
    color?: string;
    /**
     * 
     * @type {Polygon}
     * @memberof ExhibitionRoom
     */
    geoShape?: Polygon;
    /**
     * 
     * @type {string}
     * @memberof ExhibitionRoom
     */
    readonly creatorId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExhibitionRoom
     */
    readonly lastModifierId?: string;
    /**
     * Created date
     * @type {Date}
     * @memberof ExhibitionRoom
     */
    readonly createdAt?: Date;
    /**
     * Date modified
     * @type {Date}
     * @memberof ExhibitionRoom
     */
    readonly modifiedAt?: Date;
}

export function ExhibitionRoomFromJSON(json: any): ExhibitionRoom {
    return ExhibitionRoomFromJSONTyped(json, false);
}

export function ExhibitionRoomFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExhibitionRoom {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'exhibitionId': !exists(json, 'exhibitionId') ? undefined : json['exhibitionId'],
        'floorId': json['floorId'],
        'name': json['name'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'geoShape': !exists(json, 'geoShape') ? undefined : PolygonFromJSON(json['geoShape']),
        'creatorId': !exists(json, 'creatorId') ? undefined : json['creatorId'],
        'lastModifierId': !exists(json, 'lastModifierId') ? undefined : json['lastModifierId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (new Date(json['modifiedAt'])),
    };
}

export function ExhibitionRoomToJSON(value?: ExhibitionRoom | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'floorId': value.floorId,
        'name': value.name,
        'color': value.color,
        'geoShape': PolygonToJSON(value.geoShape),
    };
}


