/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContentVersionActiveCondition,
    ContentVersionActiveConditionFromJSON,
    ContentVersionActiveConditionFromJSONTyped,
    ContentVersionActiveConditionToJSON,
} from './';

/**
 * Content version (e.g. language) of an exhibition.
 * @export
 * @interface ContentVersion
 */
export interface ContentVersion {
    /**
     * 
     * @type {string}
     * @memberof ContentVersion
     */
    readonly id?: string;
    /**
     * Id of exhibition this page belongs to
     * @type {string}
     * @memberof ContentVersion
     */
    readonly exhibitionId?: string;
    /**
     * Human readable name of the content version
     * @type {string}
     * @memberof ContentVersion
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ContentVersion
     */
    language: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContentVersion
     */
    rooms: Array<string>;
    /**
     * 
     * @type {ContentVersionActiveCondition}
     * @memberof ContentVersion
     */
    activeCondition?: ContentVersionActiveCondition;
    /**
     * 
     * @type {string}
     * @memberof ContentVersion
     */
    readonly creatorId?: string;
    /**
     * 
     * @type {string}
     * @memberof ContentVersion
     */
    readonly lastModifierId?: string;
    /**
     * Created date
     * @type {Date}
     * @memberof ContentVersion
     */
    readonly createdAt?: Date;
    /**
     * Date modified
     * @type {Date}
     * @memberof ContentVersion
     */
    readonly modifiedAt?: Date;
}

export function ContentVersionFromJSON(json: any): ContentVersion {
    return ContentVersionFromJSONTyped(json, false);
}

export function ContentVersionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContentVersion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'exhibitionId': !exists(json, 'exhibitionId') ? undefined : json['exhibitionId'],
        'name': json['name'],
        'language': json['language'],
        'rooms': json['rooms'],
        'activeCondition': !exists(json, 'activeCondition') ? undefined : ContentVersionActiveConditionFromJSON(json['activeCondition']),
        'creatorId': !exists(json, 'creatorId') ? undefined : json['creatorId'],
        'lastModifierId': !exists(json, 'lastModifierId') ? undefined : json['lastModifierId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (new Date(json['modifiedAt'])),
    };
}

export function ContentVersionToJSON(value?: ContentVersion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'language': value.language,
        'rooms': value.rooms,
        'activeCondition': ContentVersionActiveConditionToJSON(value.activeCondition),
    };
}


