/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PageLayoutView,
    PageLayoutViewFromJSON,
    PageLayoutViewFromJSONTyped,
    PageLayoutViewToJSON,
    ScreenOrientation,
    ScreenOrientationFromJSON,
    ScreenOrientationFromJSONTyped,
    ScreenOrientationToJSON,
} from './';

/**
 * Describes a layout for a page
 * @export
 * @interface PageLayout
 */
export interface PageLayout {
    /**
     * 
     * @type {string}
     * @memberof PageLayout
     */
    readonly id?: string;
    /**
     * Human readable name for the layout
     * @type {string}
     * @memberof PageLayout
     */
    name: string;
    /**
     * 
     * @type {PageLayoutView}
     * @memberof PageLayout
     */
    data: PageLayoutView;
    /**
     * 
     * @type {string}
     * @memberof PageLayout
     */
    thumbnailUrl?: string;
    /**
     * Device model id
     * @type {string}
     * @memberof PageLayout
     */
    modelId?: string;
    /**
     * 
     * @type {ScreenOrientation}
     * @memberof PageLayout
     */
    screenOrientation: ScreenOrientation;
    /**
     * 
     * @type {string}
     * @memberof PageLayout
     */
    readonly creatorId?: string;
    /**
     * 
     * @type {string}
     * @memberof PageLayout
     */
    readonly lastModifierId?: string;
    /**
     * Created date
     * @type {Date}
     * @memberof PageLayout
     */
    readonly createdAt?: Date;
    /**
     * Date modified
     * @type {Date}
     * @memberof PageLayout
     */
    readonly modifiedAt?: Date;
}

export function PageLayoutFromJSON(json: any): PageLayout {
    return PageLayoutFromJSONTyped(json, false);
}

export function PageLayoutFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageLayout {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'data': PageLayoutViewFromJSON(json['data']),
        'thumbnailUrl': !exists(json, 'thumbnailUrl') ? undefined : json['thumbnailUrl'],
        'modelId': !exists(json, 'modelId') ? undefined : json['modelId'],
        'screenOrientation': ScreenOrientationFromJSON(json['screenOrientation']),
        'creatorId': !exists(json, 'creatorId') ? undefined : json['creatorId'],
        'lastModifierId': !exists(json, 'lastModifierId') ? undefined : json['lastModifierId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (new Date(json['modifiedAt'])),
    };
}

export function PageLayoutToJSON(value?: PageLayout | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'data': PageLayoutViewToJSON(value.data),
        'thumbnailUrl': value.thumbnailUrl,
        'modelId': value.modelId,
        'screenOrientation': ScreenOrientationToJSON(value.screenOrientation),
    };
}


