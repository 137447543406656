/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ContentVersion,
    ContentVersionFromJSON,
    ContentVersionToJSON,
} from '../models';

export interface CreateContentVersionRequest {
    contentVersion: ContentVersion;
    exhibitionId: string;
}

export interface DeleteContentVersionRequest {
    exhibitionId: string;
    contentVersionId: string;
}

export interface FindContentVersionRequest {
    exhibitionId: string;
    contentVersionId: string;
}

export interface ListContentVersionsRequest {
    exhibitionId: string;
    roomId?: string;
}

export interface UpdateContentVersionRequest {
    contentVersion: ContentVersion;
    exhibitionId: string;
    contentVersionId: string;
}

/**
 * no description
 */
export class ContentVersionsApi extends runtime.BaseAPI {

    /**
     * Creates new content version
     * Create a content version
     */
    async createContentVersionRaw(requestParameters: CreateContentVersionRequest): Promise<runtime.ApiResponse<ContentVersion>> {
        if (requestParameters.contentVersion === null || requestParameters.contentVersion === undefined) {
            throw new runtime.RequiredError('contentVersion','Required parameter requestParameters.contentVersion was null or undefined when calling createContentVersion.');
        }

        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling createContentVersion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/contentVersions`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContentVersionToJSON(requestParameters.contentVersion),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContentVersionFromJSON(jsonValue));
    }

    /**
     * Creates new content version
     * Create a content version
     */
    async createContentVersion(requestParameters: CreateContentVersionRequest): Promise<ContentVersion> {
        const response = await this.createContentVersionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delets content version.
     * Deletes content version.
     */
    async deleteContentVersionRaw(requestParameters: DeleteContentVersionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling deleteContentVersion.');
        }

        if (requestParameters.contentVersionId === null || requestParameters.contentVersionId === undefined) {
            throw new runtime.RequiredError('contentVersionId','Required parameter requestParameters.contentVersionId was null or undefined when calling deleteContentVersion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/contentVersions/{contentVersionId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))).replace(`{${"contentVersionId"}}`, encodeURIComponent(String(requestParameters.contentVersionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delets content version.
     * Deletes content version.
     */
    async deleteContentVersion(requestParameters: DeleteContentVersionRequest): Promise<void> {
        await this.deleteContentVersionRaw(requestParameters);
    }

    /**
     * Finds a content version by id
     * Find a content version
     */
    async findContentVersionRaw(requestParameters: FindContentVersionRequest): Promise<runtime.ApiResponse<ContentVersion>> {
        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling findContentVersion.');
        }

        if (requestParameters.contentVersionId === null || requestParameters.contentVersionId === undefined) {
            throw new runtime.RequiredError('contentVersionId','Required parameter requestParameters.contentVersionId was null or undefined when calling findContentVersion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/contentVersions/{contentVersionId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))).replace(`{${"contentVersionId"}}`, encodeURIComponent(String(requestParameters.contentVersionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContentVersionFromJSON(jsonValue));
    }

    /**
     * Finds a content version by id
     * Find a content version
     */
    async findContentVersion(requestParameters: FindContentVersionRequest): Promise<ContentVersion> {
        const response = await this.findContentVersionRaw(requestParameters);
        return await response.value();
    }

    /**
     * List content versions
     * List content versions
     */
    async listContentVersionsRaw(requestParameters: ListContentVersionsRequest): Promise<runtime.ApiResponse<Array<ContentVersion>>> {
        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling listContentVersions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.roomId !== undefined) {
            queryParameters['roomId'] = requestParameters.roomId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/contentVersions`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContentVersionFromJSON));
    }

    /**
     * List content versions
     * List content versions
     */
    async listContentVersions(requestParameters: ListContentVersionsRequest): Promise<Array<ContentVersion>> {
        const response = await this.listContentVersionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates content version.
     * Updates content version.
     */
    async updateContentVersionRaw(requestParameters: UpdateContentVersionRequest): Promise<runtime.ApiResponse<ContentVersion>> {
        if (requestParameters.contentVersion === null || requestParameters.contentVersion === undefined) {
            throw new runtime.RequiredError('contentVersion','Required parameter requestParameters.contentVersion was null or undefined when calling updateContentVersion.');
        }

        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling updateContentVersion.');
        }

        if (requestParameters.contentVersionId === null || requestParameters.contentVersionId === undefined) {
            throw new runtime.RequiredError('contentVersionId','Required parameter requestParameters.contentVersionId was null or undefined when calling updateContentVersion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/contentVersions/{contentVersionId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))).replace(`{${"contentVersionId"}}`, encodeURIComponent(String(requestParameters.contentVersionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ContentVersionToJSON(requestParameters.contentVersion),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContentVersionFromJSON(jsonValue));
    }

    /**
     * Updates content version.
     * Updates content version.
     */
    async updateContentVersion(requestParameters: UpdateContentVersionRequest): Promise<ContentVersion> {
        const response = await this.updateContentVersionRaw(requestParameters);
        return await response.value();
    }

}
