/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExhibitionPageTransitionOptions,
    ExhibitionPageTransitionOptionsFromJSON,
    ExhibitionPageTransitionOptionsFromJSONTyped,
    ExhibitionPageTransitionOptionsToJSON,
    Transition,
    TransitionFromJSON,
    TransitionFromJSONTyped,
    TransitionToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExhibitionPageTransition
 */
export interface ExhibitionPageTransition {
    /**
     * 
     * @type {Transition}
     * @memberof ExhibitionPageTransition
     */
    transition: Transition;
    /**
     * 
     * @type {string}
     * @memberof ExhibitionPageTransition
     */
    sourceLayoutId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExhibitionPageTransition
     */
    targetLayoutId?: string;
    /**
     * 
     * @type {ExhibitionPageTransitionOptions}
     * @memberof ExhibitionPageTransition
     */
    options?: ExhibitionPageTransitionOptions;
}

export function ExhibitionPageTransitionFromJSON(json: any): ExhibitionPageTransition {
    return ExhibitionPageTransitionFromJSONTyped(json, false);
}

export function ExhibitionPageTransitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExhibitionPageTransition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'transition': TransitionFromJSON(json['transition']),
        'sourceLayoutId': !exists(json, 'sourceLayoutId') ? undefined : json['sourceLayoutId'],
        'targetLayoutId': !exists(json, 'targetLayoutId') ? undefined : json['targetLayoutId'],
        'options': !exists(json, 'options') ? undefined : ExhibitionPageTransitionOptionsFromJSON(json['options']),
    };
}

export function ExhibitionPageTransitionToJSON(value?: ExhibitionPageTransition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'transition': TransitionToJSON(value.transition),
        'sourceLayoutId': value.sourceLayoutId,
        'targetLayoutId': value.targetLayoutId,
        'options': ExhibitionPageTransitionOptionsToJSON(value.options),
    };
}


