/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SubLayout,
    SubLayoutFromJSON,
    SubLayoutToJSON,
} from '../models';

export interface CreateSubLayoutRequest {
    subLayout: SubLayout;
}

export interface DeleteSubLayoutRequest {
    subLayoutId: string;
}

export interface FindSubLayoutRequest {
    subLayoutId: string;
}

export interface UpdateSubLayoutRequest {
    subLayout: SubLayout;
    subLayoutId: string;
}

/**
 * no description
 */
export class SubLayoutsApi extends runtime.BaseAPI {

    /**
     * Creates new sub layout
     * Create a sub layout
     */
    async createSubLayoutRaw(requestParameters: CreateSubLayoutRequest): Promise<runtime.ApiResponse<SubLayout>> {
        if (requestParameters.subLayout === null || requestParameters.subLayout === undefined) {
            throw new runtime.RequiredError('subLayout','Required parameter requestParameters.subLayout was null or undefined when calling createSubLayout.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/subLayouts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubLayoutToJSON(requestParameters.subLayout),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SubLayoutFromJSON(jsonValue));
    }

    /**
     * Creates new sub layout
     * Create a sub layout
     */
    async createSubLayout(requestParameters: CreateSubLayoutRequest): Promise<SubLayout> {
        const response = await this.createSubLayoutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delets sub layout.
     * Deletes sub layout.
     */
    async deleteSubLayoutRaw(requestParameters: DeleteSubLayoutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.subLayoutId === null || requestParameters.subLayoutId === undefined) {
            throw new runtime.RequiredError('subLayoutId','Required parameter requestParameters.subLayoutId was null or undefined when calling deleteSubLayout.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/subLayouts/{subLayoutId}`.replace(`{${"subLayoutId"}}`, encodeURIComponent(String(requestParameters.subLayoutId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delets sub layout.
     * Deletes sub layout.
     */
    async deleteSubLayout(requestParameters: DeleteSubLayoutRequest): Promise<void> {
        await this.deleteSubLayoutRaw(requestParameters);
    }

    /**
     * Finds a sub layout by id
     * Find a sub layout
     */
    async findSubLayoutRaw(requestParameters: FindSubLayoutRequest): Promise<runtime.ApiResponse<SubLayout>> {
        if (requestParameters.subLayoutId === null || requestParameters.subLayoutId === undefined) {
            throw new runtime.RequiredError('subLayoutId','Required parameter requestParameters.subLayoutId was null or undefined when calling findSubLayout.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/subLayouts/{subLayoutId}`.replace(`{${"subLayoutId"}}`, encodeURIComponent(String(requestParameters.subLayoutId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SubLayoutFromJSON(jsonValue));
    }

    /**
     * Finds a sub layout by id
     * Find a sub layout
     */
    async findSubLayout(requestParameters: FindSubLayoutRequest): Promise<SubLayout> {
        const response = await this.findSubLayoutRaw(requestParameters);
        return await response.value();
    }

    /**
     * List sub layouts
     * List sub layouts
     */
    async listSubLayoutsRaw(): Promise<runtime.ApiResponse<Array<SubLayout>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/subLayouts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubLayoutFromJSON));
    }

    /**
     * List sub layouts
     * List sub layouts
     */
    async listSubLayouts(): Promise<Array<SubLayout>> {
        const response = await this.listSubLayoutsRaw();
        return await response.value();
    }

    /**
     * Updates sub layout.
     * Updates sub layout.
     */
    async updateSubLayoutRaw(requestParameters: UpdateSubLayoutRequest): Promise<runtime.ApiResponse<SubLayout>> {
        if (requestParameters.subLayout === null || requestParameters.subLayout === undefined) {
            throw new runtime.RequiredError('subLayout','Required parameter requestParameters.subLayout was null or undefined when calling updateSubLayout.');
        }

        if (requestParameters.subLayoutId === null || requestParameters.subLayoutId === undefined) {
            throw new runtime.RequiredError('subLayoutId','Required parameter requestParameters.subLayoutId was null or undefined when calling updateSubLayout.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/subLayouts/{subLayoutId}`.replace(`{${"subLayoutId"}}`, encodeURIComponent(String(requestParameters.subLayoutId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SubLayoutToJSON(requestParameters.subLayout),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SubLayoutFromJSON(jsonValue));
    }

    /**
     * Updates sub layout.
     * Updates sub layout.
     */
    async updateSubLayout(requestParameters: UpdateSubLayoutRequest): Promise<SubLayout> {
        const response = await this.updateSubLayoutRaw(requestParameters);
        return await response.value();
    }

}
