/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Point,
    PointFromJSON,
    PointFromJSONTyped,
    PointToJSON,
} from './';

/**
 * 
 * @export
 * @interface RfidAntenna
 */
export interface RfidAntenna {
    /**
     * 
     * @type {string}
     * @memberof RfidAntenna
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof RfidAntenna
     */
    readonly exhibitionId?: string;
    /**
     * Id of room this RFID antenna is located at
     * @type {string}
     * @memberof RfidAntenna
     */
    roomId: string;
    /**
     * Device group id this antenna is conneted to
     * @type {string}
     * @memberof RfidAntenna
     */
    groupId?: string;
    /**
     * RFID reader module id
     * @type {string}
     * @memberof RfidAntenna
     */
    readerId: string;
    /**
     * RFID antenna number
     * @type {number}
     * @memberof RfidAntenna
     */
    antennaNumber: number;
    /**
     * Strength threshold for login events
     * @type {number}
     * @memberof RfidAntenna
     */
    visitorSessionStartThreshold: number;
    /**
     * Strength threshold for logout events
     * @type {number}
     * @memberof RfidAntenna
     */
    visitorSessionEndThreshold: number;
    /**
     * Human-readable name for this antenna
     * @type {string}
     * @memberof RfidAntenna
     */
    name: string;
    /**
     * 
     * @type {Point}
     * @memberof RfidAntenna
     */
    location: Point;
    /**
     * 
     * @type {string}
     * @memberof RfidAntenna
     */
    readonly creatorId?: string;
    /**
     * 
     * @type {string}
     * @memberof RfidAntenna
     */
    readonly lastModifierId?: string;
    /**
     * Created date
     * @type {Date}
     * @memberof RfidAntenna
     */
    readonly createdAt?: Date;
    /**
     * Date modified
     * @type {Date}
     * @memberof RfidAntenna
     */
    readonly modifiedAt?: Date;
}

export function RfidAntennaFromJSON(json: any): RfidAntenna {
    return RfidAntennaFromJSONTyped(json, false);
}

export function RfidAntennaFromJSONTyped(json: any, ignoreDiscriminator: boolean): RfidAntenna {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'exhibitionId': !exists(json, 'exhibitionId') ? undefined : json['exhibitionId'],
        'roomId': json['roomId'],
        'groupId': !exists(json, 'groupId') ? undefined : json['groupId'],
        'readerId': json['readerId'],
        'antennaNumber': json['antennaNumber'],
        'visitorSessionStartThreshold': json['visitorSessionStartThreshold'],
        'visitorSessionEndThreshold': json['visitorSessionEndThreshold'],
        'name': json['name'],
        'location': PointFromJSON(json['location']),
        'creatorId': !exists(json, 'creatorId') ? undefined : json['creatorId'],
        'lastModifierId': !exists(json, 'lastModifierId') ? undefined : json['lastModifierId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (new Date(json['modifiedAt'])),
    };
}

export function RfidAntennaToJSON(value?: RfidAntenna | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roomId': value.roomId,
        'groupId': value.groupId,
        'readerId': value.readerId,
        'antennaNumber': value.antennaNumber,
        'visitorSessionStartThreshold': value.visitorSessionStartThreshold,
        'visitorSessionEndThreshold': value.visitorSessionEndThreshold,
        'name': value.name,
        'location': PointToJSON(value.location),
    };
}


