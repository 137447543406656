/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    VisitorVariable,
    VisitorVariableFromJSON,
    VisitorVariableToJSON,
} from '../models';

export interface CreateVisitorVariableRequest {
    visitorVariable: VisitorVariable;
    exhibitionId: string;
}

export interface DeleteVisitorVariableRequest {
    exhibitionId: string;
    visitorVariableId: string;
}

export interface FindVisitorVariableRequest {
    exhibitionId: string;
    visitorVariableId: string;
}

export interface ListVisitorVariablesRequest {
    exhibitionId: string;
    name?: string;
}

export interface UpdateVisitorVariableRequest {
    visitorVariable: VisitorVariable;
    exhibitionId: string;
    visitorVariableId: string;
}

/**
 * no description
 */
export class VisitorVariablesApi extends runtime.BaseAPI {

    /**
     * Creates new exhibition visitor variable
     * Create a visitor variable
     */
    async createVisitorVariableRaw(requestParameters: CreateVisitorVariableRequest): Promise<runtime.ApiResponse<VisitorVariable>> {
        if (requestParameters.visitorVariable === null || requestParameters.visitorVariable === undefined) {
            throw new runtime.RequiredError('visitorVariable','Required parameter requestParameters.visitorVariable was null or undefined when calling createVisitorVariable.');
        }

        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling createVisitorVariable.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/visitorVariables`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VisitorVariableToJSON(requestParameters.visitorVariable),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VisitorVariableFromJSON(jsonValue));
    }

    /**
     * Creates new exhibition visitor variable
     * Create a visitor variable
     */
    async createVisitorVariable(requestParameters: CreateVisitorVariableRequest): Promise<VisitorVariable> {
        const response = await this.createVisitorVariableRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delets exhibition visitor variable.
     * Deletes visitor variable.
     */
    async deleteVisitorVariableRaw(requestParameters: DeleteVisitorVariableRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling deleteVisitorVariable.');
        }

        if (requestParameters.visitorVariableId === null || requestParameters.visitorVariableId === undefined) {
            throw new runtime.RequiredError('visitorVariableId','Required parameter requestParameters.visitorVariableId was null or undefined when calling deleteVisitorVariable.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/visitorVariables/{visitorVariableId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))).replace(`{${"visitorVariableId"}}`, encodeURIComponent(String(requestParameters.visitorVariableId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delets exhibition visitor variable.
     * Deletes visitor variable.
     */
    async deleteVisitorVariable(requestParameters: DeleteVisitorVariableRequest): Promise<void> {
        await this.deleteVisitorVariableRaw(requestParameters);
    }

    /**
     * Finds a visitor variable by id
     * Find a visitor variable
     */
    async findVisitorVariableRaw(requestParameters: FindVisitorVariableRequest): Promise<runtime.ApiResponse<VisitorVariable>> {
        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling findVisitorVariable.');
        }

        if (requestParameters.visitorVariableId === null || requestParameters.visitorVariableId === undefined) {
            throw new runtime.RequiredError('visitorVariableId','Required parameter requestParameters.visitorVariableId was null or undefined when calling findVisitorVariable.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/visitorVariables/{visitorVariableId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))).replace(`{${"visitorVariableId"}}`, encodeURIComponent(String(requestParameters.visitorVariableId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VisitorVariableFromJSON(jsonValue));
    }

    /**
     * Finds a visitor variable by id
     * Find a visitor variable
     */
    async findVisitorVariable(requestParameters: FindVisitorVariableRequest): Promise<VisitorVariable> {
        const response = await this.findVisitorVariableRaw(requestParameters);
        return await response.value();
    }

    /**
     * List exhibition Visitor variables
     * List Visitor variables
     */
    async listVisitorVariablesRaw(requestParameters: ListVisitorVariablesRequest): Promise<runtime.ApiResponse<Array<VisitorVariable>>> {
        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling listVisitorVariables.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/visitorVariables`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VisitorVariableFromJSON));
    }

    /**
     * List exhibition Visitor variables
     * List Visitor variables
     */
    async listVisitorVariables(requestParameters: ListVisitorVariablesRequest): Promise<Array<VisitorVariable>> {
        const response = await this.listVisitorVariablesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates visitor variable.
     * Updates visitor variable.
     */
    async updateVisitorVariableRaw(requestParameters: UpdateVisitorVariableRequest): Promise<runtime.ApiResponse<VisitorVariable>> {
        if (requestParameters.visitorVariable === null || requestParameters.visitorVariable === undefined) {
            throw new runtime.RequiredError('visitorVariable','Required parameter requestParameters.visitorVariable was null or undefined when calling updateVisitorVariable.');
        }

        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling updateVisitorVariable.');
        }

        if (requestParameters.visitorVariableId === null || requestParameters.visitorVariableId === undefined) {
            throw new runtime.RequiredError('visitorVariableId','Required parameter requestParameters.visitorVariableId was null or undefined when calling updateVisitorVariable.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/visitorVariables/{visitorVariableId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))).replace(`{${"visitorVariableId"}}`, encodeURIComponent(String(requestParameters.visitorVariableId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: VisitorVariableToJSON(requestParameters.visitorVariable),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VisitorVariableFromJSON(jsonValue));
    }

    /**
     * Updates visitor variable.
     * Updates visitor variable.
     */
    async updateVisitorVariable(requestParameters: UpdateVisitorVariableRequest): Promise<VisitorVariable> {
        const response = await this.updateVisitorVariableRaw(requestParameters);
        return await response.value();
    }

}
