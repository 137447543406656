/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Defines how device group visitor sessions start. * When \"othersblock\" is selected, group will not start a  visitor session when session is active on another group. * When \"endothers\" is selected, group will end sessions in other groups and start new in this group 
 * @export
 * @enum {string}
 */
export enum DeviceGroupVisitorSessionStartStrategy {
    Othersblock = 'othersblock',
    Endothers = 'endothers'
}

export function DeviceGroupVisitorSessionStartStrategyFromJSON(json: any): DeviceGroupVisitorSessionStartStrategy {
    return DeviceGroupVisitorSessionStartStrategyFromJSONTyped(json, false);
}

export function DeviceGroupVisitorSessionStartStrategyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceGroupVisitorSessionStartStrategy {
    return json as DeviceGroupVisitorSessionStartStrategy;
}

export function DeviceGroupVisitorSessionStartStrategyToJSON(value?: DeviceGroupVisitorSessionStartStrategy | null): any {
    return value as any;
}

