/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContentVersionActiveCondition
 */
export interface ContentVersionActiveCondition {
    /**
     * 
     * @type {string}
     * @memberof ContentVersionActiveCondition
     */
    userVariable: string;
    /**
     * 
     * @type {string}
     * @memberof ContentVersionActiveCondition
     */
    equals?: string;
}

export function ContentVersionActiveConditionFromJSON(json: any): ContentVersionActiveCondition {
    return ContentVersionActiveConditionFromJSONTyped(json, false);
}

export function ContentVersionActiveConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContentVersionActiveCondition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userVariable': json['userVariable'],
        'equals': !exists(json, 'equals') ? undefined : json['equals'],
    };
}

export function ContentVersionActiveConditionToJSON(value?: ContentVersionActiveCondition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userVariable': value.userVariable,
        'equals': value.equals,
    };
}


