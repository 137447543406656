/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VisitorSessionState,
    VisitorSessionStateFromJSON,
    VisitorSessionStateFromJSONTyped,
    VisitorSessionStateToJSON,
    VisitorSessionVariable,
    VisitorSessionVariableFromJSON,
    VisitorSessionVariableFromJSONTyped,
    VisitorSessionVariableToJSON,
    VisitorSessionVisitedDeviceGroup,
    VisitorSessionVisitedDeviceGroupFromJSON,
    VisitorSessionVisitedDeviceGroupFromJSONTyped,
    VisitorSessionVisitedDeviceGroupToJSON,
} from './';

/**
 * 
 * @export
 * @interface VisitorSessionV2
 */
export interface VisitorSessionV2 {
    /**
     * Unique id for the session
     * @type {string}
     * @memberof VisitorSessionV2
     */
    readonly id?: string;
    /**
     * Exhibition id the visiotr was attending to
     * @type {string}
     * @memberof VisitorSessionV2
     */
    readonly exhibitionId?: string;
    /**
     * 
     * @type {VisitorSessionState}
     * @memberof VisitorSessionV2
     */
    state: VisitorSessionState;
    /**
     * 
     * @type {string}
     * @memberof VisitorSessionV2
     */
    language: string;
    /**
     * 
     * @type {Array<VisitorSessionVariable>}
     * @memberof VisitorSessionV2
     */
    variables?: Array<VisitorSessionVariable>;
    /**
     * 
     * @type {Array<string>}
     * @memberof VisitorSessionV2
     */
    visitorIds: Array<string>;
    /**
     * 
     * @type {Array<VisitorSessionVisitedDeviceGroup>}
     * @memberof VisitorSessionV2
     */
    visitedDeviceGroups?: Array<VisitorSessionVisitedDeviceGroup>;
    /**
     * Read-only list of tags associated with this session
     * @type {Array<string>}
     * @memberof VisitorSessionV2
     */
    readonly tags?: Array<string>;
    /**
     * Time when the visitor session expires
     * @type {Date}
     * @memberof VisitorSessionV2
     */
    readonly expiresAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof VisitorSessionV2
     */
    readonly creatorId?: string;
    /**
     * 
     * @type {string}
     * @memberof VisitorSessionV2
     */
    readonly lastModifierId?: string;
    /**
     * Created date
     * @type {Date}
     * @memberof VisitorSessionV2
     */
    readonly createdAt?: Date;
    /**
     * Date modified
     * @type {Date}
     * @memberof VisitorSessionV2
     */
    readonly modifiedAt?: Date;
}

export function VisitorSessionV2FromJSON(json: any): VisitorSessionV2 {
    return VisitorSessionV2FromJSONTyped(json, false);
}

export function VisitorSessionV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): VisitorSessionV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'exhibitionId': !exists(json, 'exhibitionId') ? undefined : json['exhibitionId'],
        'state': VisitorSessionStateFromJSON(json['state']),
        'language': json['language'],
        'variables': !exists(json, 'variables') ? undefined : ((json['variables'] as Array<any>).map(VisitorSessionVariableFromJSON)),
        'visitorIds': json['visitorIds'],
        'visitedDeviceGroups': !exists(json, 'visitedDeviceGroups') ? undefined : ((json['visitedDeviceGroups'] as Array<any>).map(VisitorSessionVisitedDeviceGroupFromJSON)),
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'expiresAt': !exists(json, 'expiresAt') ? undefined : (new Date(json['expiresAt'])),
        'creatorId': !exists(json, 'creatorId') ? undefined : json['creatorId'],
        'lastModifierId': !exists(json, 'lastModifierId') ? undefined : json['lastModifierId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (new Date(json['modifiedAt'])),
    };
}

export function VisitorSessionV2ToJSON(value?: VisitorSessionV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'state': VisitorSessionStateToJSON(value.state),
        'language': value.language,
        'variables': value.variables === undefined ? undefined : ((value.variables as Array<any>).map(VisitorSessionVariableToJSON)),
        'visitorIds': value.visitorIds,
        'visitedDeviceGroups': value.visitedDeviceGroups === undefined ? undefined : ((value.visitedDeviceGroups as Array<any>).map(VisitorSessionVisitedDeviceGroupToJSON)),
    };
}


