/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Visitor variable type
 * @export
 * @enum {string}
 */
export enum VisitorVariableType {
    Text = 'text',
    Number = 'number',
    Boolean = 'boolean',
    Enumerated = 'enumerated'
}

export function VisitorVariableTypeFromJSON(json: any): VisitorVariableType {
    return VisitorVariableTypeFromJSONTyped(json, false);
}

export function VisitorVariableTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): VisitorVariableType {
    return json as VisitorVariableType;
}

export function VisitorVariableTypeToJSON(value?: VisitorVariableType | null): any {
    return value as any;
}

