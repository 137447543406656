/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeviceGroupVisitorSessionStartStrategy,
    DeviceGroupVisitorSessionStartStrategyFromJSON,
    DeviceGroupVisitorSessionStartStrategyFromJSONTyped,
    DeviceGroupVisitorSessionStartStrategyToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExhibitionDeviceGroup
 */
export interface ExhibitionDeviceGroup {
    /**
     * 
     * @type {string}
     * @memberof ExhibitionDeviceGroup
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExhibitionDeviceGroup
     */
    readonly exhibitionId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExhibitionDeviceGroup
     */
    roomId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExhibitionDeviceGroup
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExhibitionDeviceGroup
     */
    allowVisitorSessionCreation: boolean;
    /**
     * Timeout for visitor session
     * @type {number}
     * @memberof ExhibitionDeviceGroup
     */
    visitorSessionEndTimeout: number;
    /**
     * Time after inactive visitor will returned to the index page. Ignored if not specified
     * @type {number}
     * @memberof ExhibitionDeviceGroup
     */
    indexPageTimeout?: number;
    /**
     * 
     * @type {DeviceGroupVisitorSessionStartStrategy}
     * @memberof ExhibitionDeviceGroup
     */
    visitorSessionStartStrategy: DeviceGroupVisitorSessionStartStrategy;
    /**
     * 
     * @type {string}
     * @memberof ExhibitionDeviceGroup
     */
    readonly creatorId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExhibitionDeviceGroup
     */
    readonly lastModifierId?: string;
    /**
     * Created date
     * @type {Date}
     * @memberof ExhibitionDeviceGroup
     */
    readonly createdAt?: Date;
    /**
     * Date modified
     * @type {Date}
     * @memberof ExhibitionDeviceGroup
     */
    readonly modifiedAt?: Date;
}

export function ExhibitionDeviceGroupFromJSON(json: any): ExhibitionDeviceGroup {
    return ExhibitionDeviceGroupFromJSONTyped(json, false);
}

export function ExhibitionDeviceGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExhibitionDeviceGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'exhibitionId': !exists(json, 'exhibitionId') ? undefined : json['exhibitionId'],
        'roomId': !exists(json, 'roomId') ? undefined : json['roomId'],
        'name': json['name'],
        'allowVisitorSessionCreation': json['allowVisitorSessionCreation'],
        'visitorSessionEndTimeout': json['visitorSessionEndTimeout'],
        'indexPageTimeout': !exists(json, 'indexPageTimeout') ? undefined : json['indexPageTimeout'],
        'visitorSessionStartStrategy': DeviceGroupVisitorSessionStartStrategyFromJSON(json['visitorSessionStartStrategy']),
        'creatorId': !exists(json, 'creatorId') ? undefined : json['creatorId'],
        'lastModifierId': !exists(json, 'lastModifierId') ? undefined : json['lastModifierId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (new Date(json['modifiedAt'])),
    };
}

export function ExhibitionDeviceGroupToJSON(value?: ExhibitionDeviceGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roomId': value.roomId,
        'name': value.name,
        'allowVisitorSessionCreation': value.allowVisitorSessionCreation,
        'visitorSessionEndTimeout': value.visitorSessionEndTimeout,
        'indexPageTimeout': value.indexPageTimeout,
        'visitorSessionStartStrategy': DeviceGroupVisitorSessionStartStrategyToJSON(value.visitorSessionStartStrategy),
    };
}


