/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ExhibitionDevice,
    ExhibitionDeviceFromJSON,
    ExhibitionDeviceToJSON,
} from '../models';

export interface CreateExhibitionDeviceRequest {
    exhibitionDevice: ExhibitionDevice;
    exhibitionId: string;
}

export interface DeleteExhibitionDeviceRequest {
    exhibitionId: string;
    deviceId: string;
}

export interface FindExhibitionDeviceRequest {
    exhibitionId: string;
    deviceId: string;
}

export interface ListExhibitionDevicesRequest {
    exhibitionId: string;
    exhibitionGroupId?: string;
    deviceModelId?: string;
}

export interface UpdateExhibitionDeviceRequest {
    exhibitionDevice: ExhibitionDevice;
    exhibitionId: string;
    deviceId: string;
}

/**
 * no description
 */
export class ExhibitionDevicesApi extends runtime.BaseAPI {

    /**
     * Creates new exhibition device
     * Create a device
     */
    async createExhibitionDeviceRaw(requestParameters: CreateExhibitionDeviceRequest): Promise<runtime.ApiResponse<ExhibitionDevice>> {
        if (requestParameters.exhibitionDevice === null || requestParameters.exhibitionDevice === undefined) {
            throw new runtime.RequiredError('exhibitionDevice','Required parameter requestParameters.exhibitionDevice was null or undefined when calling createExhibitionDevice.');
        }

        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling createExhibitionDevice.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/devices`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExhibitionDeviceToJSON(requestParameters.exhibitionDevice),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExhibitionDeviceFromJSON(jsonValue));
    }

    /**
     * Creates new exhibition device
     * Create a device
     */
    async createExhibitionDevice(requestParameters: CreateExhibitionDeviceRequest): Promise<ExhibitionDevice> {
        const response = await this.createExhibitionDeviceRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delets exhibition device.
     * Deletes device.
     */
    async deleteExhibitionDeviceRaw(requestParameters: DeleteExhibitionDeviceRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling deleteExhibitionDevice.');
        }

        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling deleteExhibitionDevice.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/devices/{deviceId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))).replace(`{${"deviceId"}}`, encodeURIComponent(String(requestParameters.deviceId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delets exhibition device.
     * Deletes device.
     */
    async deleteExhibitionDevice(requestParameters: DeleteExhibitionDeviceRequest): Promise<void> {
        await this.deleteExhibitionDeviceRaw(requestParameters);
    }

    /**
     * Finds a device by id
     * Find a device
     */
    async findExhibitionDeviceRaw(requestParameters: FindExhibitionDeviceRequest): Promise<runtime.ApiResponse<ExhibitionDevice>> {
        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling findExhibitionDevice.');
        }

        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling findExhibitionDevice.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/devices/{deviceId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))).replace(`{${"deviceId"}}`, encodeURIComponent(String(requestParameters.deviceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExhibitionDeviceFromJSON(jsonValue));
    }

    /**
     * Finds a device by id
     * Find a device
     */
    async findExhibitionDevice(requestParameters: FindExhibitionDeviceRequest): Promise<ExhibitionDevice> {
        const response = await this.findExhibitionDeviceRaw(requestParameters);
        return await response.value();
    }

    /**
     * List exhibition devices
     * List devices
     */
    async listExhibitionDevicesRaw(requestParameters: ListExhibitionDevicesRequest): Promise<runtime.ApiResponse<Array<ExhibitionDevice>>> {
        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling listExhibitionDevices.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.exhibitionGroupId !== undefined) {
            queryParameters['exhibitionGroupId'] = requestParameters.exhibitionGroupId;
        }

        if (requestParameters.deviceModelId !== undefined) {
            queryParameters['deviceModelId'] = requestParameters.deviceModelId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/devices`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExhibitionDeviceFromJSON));
    }

    /**
     * List exhibition devices
     * List devices
     */
    async listExhibitionDevices(requestParameters: ListExhibitionDevicesRequest): Promise<Array<ExhibitionDevice>> {
        const response = await this.listExhibitionDevicesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates device.
     * Updates device.
     */
    async updateExhibitionDeviceRaw(requestParameters: UpdateExhibitionDeviceRequest): Promise<runtime.ApiResponse<ExhibitionDevice>> {
        if (requestParameters.exhibitionDevice === null || requestParameters.exhibitionDevice === undefined) {
            throw new runtime.RequiredError('exhibitionDevice','Required parameter requestParameters.exhibitionDevice was null or undefined when calling updateExhibitionDevice.');
        }

        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling updateExhibitionDevice.');
        }

        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling updateExhibitionDevice.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/devices/{deviceId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))).replace(`{${"deviceId"}}`, encodeURIComponent(String(requestParameters.deviceId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ExhibitionDeviceToJSON(requestParameters.exhibitionDevice),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExhibitionDeviceFromJSON(jsonValue));
    }

    /**
     * Updates device.
     * Updates device.
     */
    async updateExhibitionDevice(requestParameters: UpdateExhibitionDeviceRequest): Promise<ExhibitionDevice> {
        const response = await this.updateExhibitionDeviceRaw(requestParameters);
        return await response.value();
    }

}
