/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GroupContentVersion,
    GroupContentVersionFromJSON,
    GroupContentVersionToJSON,
} from '../models';

export interface CreateGroupContentVersionRequest {
    groupContentVersion: GroupContentVersion;
    exhibitionId: string;
}

export interface DeleteGroupContentVersionRequest {
    exhibitionId: string;
    groupContentVersionId: string;
}

export interface FindGroupContentVersionRequest {
    exhibitionId: string;
    groupContentVersionId: string;
}

export interface ListGroupContentVersionsRequest {
    exhibitionId: string;
    contentVersionId?: string;
    deviceGroupId?: string;
}

export interface UpdateGroupContentVersionRequest {
    groupContentVersion: GroupContentVersion;
    exhibitionId: string;
    groupContentVersionId: string;
}

/**
 * no description
 */
export class GroupContentVersionsApi extends runtime.BaseAPI {

    /**
     * Creates new group content version
     * Create a group content version
     */
    async createGroupContentVersionRaw(requestParameters: CreateGroupContentVersionRequest): Promise<runtime.ApiResponse<GroupContentVersion>> {
        if (requestParameters.groupContentVersion === null || requestParameters.groupContentVersion === undefined) {
            throw new runtime.RequiredError('groupContentVersion','Required parameter requestParameters.groupContentVersion was null or undefined when calling createGroupContentVersion.');
        }

        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling createGroupContentVersion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/groupContentVersions`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GroupContentVersionToJSON(requestParameters.groupContentVersion),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupContentVersionFromJSON(jsonValue));
    }

    /**
     * Creates new group content version
     * Create a group content version
     */
    async createGroupContentVersion(requestParameters: CreateGroupContentVersionRequest): Promise<GroupContentVersion> {
        const response = await this.createGroupContentVersionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delets group content version.
     * Deletes group content version.
     */
    async deleteGroupContentVersionRaw(requestParameters: DeleteGroupContentVersionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling deleteGroupContentVersion.');
        }

        if (requestParameters.groupContentVersionId === null || requestParameters.groupContentVersionId === undefined) {
            throw new runtime.RequiredError('groupContentVersionId','Required parameter requestParameters.groupContentVersionId was null or undefined when calling deleteGroupContentVersion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/groupContentVersions/{groupContentVersionId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))).replace(`{${"groupContentVersionId"}}`, encodeURIComponent(String(requestParameters.groupContentVersionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delets group content version.
     * Deletes group content version.
     */
    async deleteGroupContentVersion(requestParameters: DeleteGroupContentVersionRequest): Promise<void> {
        await this.deleteGroupContentVersionRaw(requestParameters);
    }

    /**
     * Finds a group content version by id
     * Find a group content version
     */
    async findGroupContentVersionRaw(requestParameters: FindGroupContentVersionRequest): Promise<runtime.ApiResponse<GroupContentVersion>> {
        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling findGroupContentVersion.');
        }

        if (requestParameters.groupContentVersionId === null || requestParameters.groupContentVersionId === undefined) {
            throw new runtime.RequiredError('groupContentVersionId','Required parameter requestParameters.groupContentVersionId was null or undefined when calling findGroupContentVersion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/groupContentVersions/{groupContentVersionId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))).replace(`{${"groupContentVersionId"}}`, encodeURIComponent(String(requestParameters.groupContentVersionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupContentVersionFromJSON(jsonValue));
    }

    /**
     * Finds a group content version by id
     * Find a group content version
     */
    async findGroupContentVersion(requestParameters: FindGroupContentVersionRequest): Promise<GroupContentVersion> {
        const response = await this.findGroupContentVersionRaw(requestParameters);
        return await response.value();
    }

    /**
     * List group content versions
     * List group content versions
     */
    async listGroupContentVersionsRaw(requestParameters: ListGroupContentVersionsRequest): Promise<runtime.ApiResponse<Array<GroupContentVersion>>> {
        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling listGroupContentVersions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.contentVersionId !== undefined) {
            queryParameters['contentVersionId'] = requestParameters.contentVersionId;
        }

        if (requestParameters.deviceGroupId !== undefined) {
            queryParameters['deviceGroupId'] = requestParameters.deviceGroupId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/groupContentVersions`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GroupContentVersionFromJSON));
    }

    /**
     * List group content versions
     * List group content versions
     */
    async listGroupContentVersions(requestParameters: ListGroupContentVersionsRequest): Promise<Array<GroupContentVersion>> {
        const response = await this.listGroupContentVersionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates group content version.
     * Updates group content version.
     */
    async updateGroupContentVersionRaw(requestParameters: UpdateGroupContentVersionRequest): Promise<runtime.ApiResponse<GroupContentVersion>> {
        if (requestParameters.groupContentVersion === null || requestParameters.groupContentVersion === undefined) {
            throw new runtime.RequiredError('groupContentVersion','Required parameter requestParameters.groupContentVersion was null or undefined when calling updateGroupContentVersion.');
        }

        if (requestParameters.exhibitionId === null || requestParameters.exhibitionId === undefined) {
            throw new runtime.RequiredError('exhibitionId','Required parameter requestParameters.exhibitionId was null or undefined when calling updateGroupContentVersion.');
        }

        if (requestParameters.groupContentVersionId === null || requestParameters.groupContentVersionId === undefined) {
            throw new runtime.RequiredError('groupContentVersionId','Required parameter requestParameters.groupContentVersionId was null or undefined when calling updateGroupContentVersion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/exhibitions/{exhibitionId}/groupContentVersions/{groupContentVersionId}`.replace(`{${"exhibitionId"}}`, encodeURIComponent(String(requestParameters.exhibitionId))).replace(`{${"groupContentVersionId"}}`, encodeURIComponent(String(requestParameters.groupContentVersionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GroupContentVersionToJSON(requestParameters.groupContentVersion),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupContentVersionFromJSON(jsonValue));
    }

    /**
     * Updates group content version.
     * Updates group content version.
     */
    async updateGroupContentVersion(requestParameters: UpdateGroupContentVersionRequest): Promise<GroupContentVersion> {
        const response = await this.updateGroupContentVersionRaw(requestParameters);
        return await response.value();
    }

}
