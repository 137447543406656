/* tslint:disable */
/* eslint-disable */
/**
 * Muisti API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Coordinates,
    CoordinatesFromJSON,
    CoordinatesFromJSONTyped,
    CoordinatesToJSON,
} from './';

/**
 * Geo bounds object
 * @export
 * @interface Bounds
 */
export interface Bounds {
    /**
     * 
     * @type {Coordinates}
     * @memberof Bounds
     */
    southWestCorner: Coordinates;
    /**
     * 
     * @type {Coordinates}
     * @memberof Bounds
     */
    northEastCorner: Coordinates;
}

export function BoundsFromJSON(json: any): Bounds {
    return BoundsFromJSONTyped(json, false);
}

export function BoundsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Bounds {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'southWestCorner': CoordinatesFromJSON(json['southWestCorner']),
        'northEastCorner': CoordinatesFromJSON(json['northEastCorner']),
    };
}

export function BoundsToJSON(value?: Bounds | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'southWestCorner': CoordinatesToJSON(value.southWestCorner),
        'northEastCorner': CoordinatesToJSON(value.northEastCorner),
    };
}


